import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext
} from 'react'
import style from './SignForm.module.scss'
import SignOnFederated from './SignOnFederated'
import SignOnLocal from './SignOnLocal'
import GlobalContext from 'reducer/global'
import { LoadingOverlay } from 'tools/Loader'
// import SiteLogo from 'components/Root/SiteLogo'
import paths from 'constants/paths'
import config from 'constants/config'

////////////////////////////////////////////////////////////////////////////////

// eventually query the backend for this
const HEADING = (
  <div className="mt5 mb3 b white f5 lh-copy fw1 tc i fo-frame pa3">
    Fallout 76 independent companion app
    <div className="mt2">
      If you have questions, you can contact us through our discord, listed in our{' '}
      <a href="/info/">Support and Terms of Use</a>.
    </div>
  </div>
)
const TERMS = (
  <div className={`f7 tc mt2`}>
    <i>
      By using this account you agree to our{' '}
      <a href="/legal/">Privacy and Use Policy</a>
    </i>
  </div>
)

const tabHeaders = [
  { txt: 'Sign in', tok: 'signin' },
  { txt: 'Sign up', tok: 'signup' }
]
const tabHeadersMap = tabHeaders.reduce((map, item) => {
  map[item.tok] = item
  return map
}, {})

////////////////////////////////////////////////////////////////////////////////
function SignForm({ signup = false, children = undefined, heading = true }) {
  const [status, setStatus] = useState(undefined)
  const [currentTab, setCurrentTab] = useState(signup ? 'signup' : 'signin')
  const boxPadding = 'ph3 ph4-ns'
  const elemView = useRef()
  const [{ authx }] = useContext(GlobalContext)

  useEffect(() => {
    setCurrentTab(signup ? 'signup' : 'signin')
  }, [signup])

  useEffect(() => {
    if (elemView.current !== undefined) {
      // @ts-ignore
      elemView.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elemView])

  const tabClick = useCallback(
    (item, index, event) => {
      event.preventDefault()
      setStatus('')
      setCurrentTab(tabHeaders[index].tok)
    },
    [setStatus]
  )

  return (
    <div className="mb6 mb0-ns">
      <div ref={elemView} />
      {heading && HEADING}
      {children}
      {authx.handshaking ? <LoadingOverlay onlyFull={true} /> : null}
      <div
        className={`${style.authbox} fuzzbox fo-frame flex items-start justify-center mb5 w-100`}
      >
        <div className="w-100">
          <div className={style.tabs}>
            <div className={`${style.tabList}`}>
              {tabHeaders.map((item, index) => {
                return (
                  <div
                    onClick={(e) => tabClick(item, index, e)}
                    className={`${style.tab} ${
                      item.tok === currentTab
                        ? style.selected + ' '
                        : // : 'theme-fade-layer50'
                          'theme-hilite-layer2'
                    } hover-hilite`}
                    key={index}
                  >
                    {item.txt}
                  </div>
                )
              })}
            </div>
            {config.auth.federated && (
              <div
                className={`${boxPadding} items-center ba b--transparent pb1 pt3 pt4-ns`}
              >
                <div className="primary mb3 tc w-100">Sign in with:</div>
                <SignOnFederated status={status} setStatus={setStatus} />
                {config.auth.local && (
                  <div className="hrstrike pt3">
                    <div className="f6 primary b">or</div>
                  </div>
                )}
              </div>
            )}
            {config.auth.local && (
              <div className={`${boxPadding} ${style.tabPanel} pb1 mt4`}>
                {config.auth.federated && (
                  <div className="primary mb3 tc w-100">With {config.name}:</div>
                )}
                <SignOnLocal
                  signup={currentTab === 'signup'}
                  label={tabHeadersMap[currentTab].txt}
                  setStatus={setStatus}
                  status={status}
                />
              </div>
            )}
            <div className={`${boxPadding} tc fw2`}>
              {status ? (
                <div className={`${style.authstatus} visible pa2 mt2`}>
                  {status}
                </div>
              ) : null}
            </div>
            <div
              className={`${boxPadding} pv2 pv3-m pv4-l items-center ba b--transparent`}
            >
              {config.auth.local && (
                <div className="i f7 tc mb2">
                  <a href={paths.password}>Forgot Password?</a>
                </div>
              )}
              {TERMS}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignForm
