import React, { useContext } from 'react'
import UserContext from '../reducer'
import Toggle from 'tools/Toggle'
// import FeatureToggles from '../tools/FeatureToggles'
// import ThemeToggle from '../tools/ThemeToggle'
import ChangePassword from './ChangePassword'
import IdentifiedUser from 'tools/IdentifiedUser'
import DeleteAccount from './DeleteAccount'
import { ExtLink } from 'tools/Links'
import config from 'constants/config'

function Account() {
  const [user] = useContext(UserContext)

  return (
    <IdentifiedUser>
      <div className="pa4">
        <div className="f3 heading mb3">
          <b>Please Note!</b>
        </div>
        <div className="mb4 i">
          RogueTrader is part of{' '}
          <ExtLink to="https://revenant.studio">
            Revenant Creative Studio's
          </ExtLink>{' '}
          network, so these settings are global across all systems, and email
          messages come from
          <code className="b ml2">@revenant.studio</code>.
        </div>

        <FederatedAuthPrefs user={user} />
        <ChangePassword />
        <DeleteAccount />
      </div>
    </IdentifiedUser>
  )
}

////////////////////////////////////////////////////////////////////////////////

function FederatedAuthPrefs({ user }) {
  if (!config.federated) {
    return null
  }
  return (
    <>
      <label className="f5 b db mb3 heading">Sign in Preference</label>
      <div className="flex-items pl2 mb4">
        <Toggle
          onChange={() => {
            const value = !user.settings?.authAllowed?.google
            const settings = user.settings
            if (!settings.authAllowed) {
              settings.authAllowed = {}
            }
            settings.authAllowed.google = value
            user.onSave(
              { token: 'settings', component: 'user', componentId: false },
              JSON.stringify(settings)
            )
          }}
          value={user.settings?.authAllowed?.google}
        >
          Allow Google Authentication (using any of the emails on this account)
        </Toggle>
      </div>
    </>
  )
}

export default Account
