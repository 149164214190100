import React, { createContext, useReducer } from 'react'
import { removeItem, upsertItem } from 'utils/array'
import {
  normalizeUser,
  normalizeUserTags,
  normalizeUserAvatar,
  changeUserData,
  defaultUser,
  changePassForm
} from './normalize'
import { normalizeParentFiles } from 'tools/Files/normalize'

export const R_USER = {
  SET: 100,
  SET_TAGS: 120,
  CHANGE: 200,
  CHANGE_DATA: 210,
  MERGE: 300,
  UPSERT_FILE: 400,
  DELETE_FILE: 410,
  CHANGE_PASSFORM: 500,
  NEW_AVATAR: 600
}

////////////////////////////////////////////////////////////////////////////////
function reducer(state, { type, value, ...args }) {
  switch (type) {
    case R_USER.UPSERT_FILE:
      return normalizeParentFiles(state, upsertItem(state.files, value))

    case R_USER.DELETE_FILE:
      return normalizeParentFiles(state, removeItem(state.files, value))

    case R_USER.SET:
      return normalizeUser(state, value)

    case R_USER.SET_TAGS:
      return normalizeUserTags({ ...state }, value)

    case R_USER.CHANGE:
      return { ...state, [args.token]: value }

    case R_USER.CHANGE_PASSFORM:
      return changePassForm(state, args)

    case R_USER.CHANGE_DATA:
      return changeUserData(state, value, args.group, args.token)

    case R_USER.NEW_AVATAR:
      return { ...normalizeUserAvatar(state, value) }

    case R_USER.MERGE:
      return { ...state, ...value }

    default:
      throw new Error(`user - no such action.type: ${type}!`)
  }
}

export const UserContext = createContext(null)
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultUser)
  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
