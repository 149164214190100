import apollo from 'utils/apollo'

import { authXinitialState } from 'utils/signon'
import { defaultUser } from 'utils/user'

import { menu, bar } from 'components/Root/NavBar/config'

/// used in Doc
// import { Cookies } from 'react-cookie'
//
// export const cookies = new Cookies()
// export const COOKIE_NAME = 'e'
// const starting = cookies.get(COOKIE_NAME) || {}

////////////////////////////////////////////////////////////////////////////////
export const initialState = {
  apolloInit: false,
  gameSystem: {},
  scroll: false,
  query: {},
  saving: false,
  savingKeys: {},
  prefs: {
    nav: { gaz: 1 },
    show: {},
    filters: {},
    orgList: {
      name: ''
    },
    orgList_t: 0,
    projectList: {
      goal: 'any',
      needs: [],
      files: [],
      activity: 'quarter',
      name: ''
    },
    projectList_t: 0,
    connect: {
      name: '',
      topics: []
    },
    connect_t: 0,
    skip: true
  },
  page: {
    title: ''
  },
  nav: { bar, menu },
  history: [window.location.pathname],
  apollo: apollo(() => {}),
  interfaceConfig: {},
  user: defaultUser(),
  authx: authXinitialState()
}
