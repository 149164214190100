import React, { useState } from 'react'
import background from './signin.jpg' // eslint-disable-line
import { NavLink } from 'react-router-dom'
import NavBar from './NavBar'
import style from './index.module.scss'
import config from 'constants/config'

const sizes = [1440, 720, 480]
let backgrounds = [
  { img: 'fo-pa-helmet', title: 'PA Helmet', artist: 'Not Protos', link: '' },
  // { img: 'fo-nuka', title: 'Nuka', artist: 'Not Protos', link: '' },
  // { img: 'fo-nuka-girl', title: 'Nuka Girl', artist: 'Not Protos', link: '' },
  {
    img: 'mothman_in_the_cranberry_bog_by_justinspired_ddlwhm3',
    title: 'Mothman in the Cranberry Bog',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'impostor_sheepsquatch_by_justinspired_ddlwh4v',
    title: 'Imposter Sheepsquatch',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'scorchbeast_queen_by_justinspired_ddevyn8',
    title: 'Scorchbeast Queen',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'scorchbeast_face_off_by_justinspired_ddevybe',
    title: 'Scorchbeast Face Off',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'beast_friends_by_justinspired_dd76d4b',
    title: 'Beast Friends',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'dogmeat_daydream_by_justinspired_dd6urvj',
    title: 'Dogmeat Daydream',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'sheepsquatch_quills_by_justinspired_dd6sz2b',
    title: 'Sheepsquatch Quills',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'scorchbeast_scream_by_justinspired_dcyuxn7',
    title: 'Scorchbeast Scream',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'girls_night_out_by_justinspired_dctfbe3',
    title: 'Girls Night Out',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'deathclaw_distraction_by_justinspired_dcbzmh9',
    title: 'Deathclaw Distraction',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'glowing_mirelurk_king_by_justinspired_dc5rsa0',
    title: 'Glowing Mirelurk King',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'sustained_screaming_by_justinspired_dbxj77f',
    title: 'Sustained Screaming',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  },
  {
    img: 'the_overseer_by_justinspired_dbrrw0u',
    title: 'The Overseer',
    artist: 'JustInspired',
    link: 'https://www.deviantart.com/justinspired'
  }
]
// {
//   img: 'red_rocket_by_justinspired_dc0ni6e',
//   title: 'Red Rocket',
//   artist: 'JustInspired',
//   link: 'https://www.deviantart.com/justinspired'
// },

function UserProfile(props) {
  const [bgimg] = useState(
    backgrounds[Math.floor(Math.random() * backgrounds.length)]
  )
  const srcset = sizes.map((size) => {
    return {
      url: `/assets/bgs/${bgimg.img}-${size}.jpg`,
      width: size
    }
  })

  return (
    <>
      <div className={style.bgWrapper}>
        <div
          className={`${style.background}`}
          style={{
            backgroundImage: 'url(' + srcset[0].url + ')'
          }}
        ></div>
      </div>
      <div className={style.bgContent}>
        <NavBar />
        {props.children}
        <div
          className={`${style.bgCredit} flex items-center justify-between f7 w-100`}
        >
          <div className={style.embiggen}>
            {bgimg.title ? (
              <>
                backdrop:&nbsp;
                <i>
                  <b>{bgimg.title}</b>
                </i>
                &nbsp;
                {bgimg.artist ? (
                  <>
                    by&nbsp;
                    {bgimg.link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={bgimg.link}
                      >
                        {bgimg.artist}
                      </a>
                    ) : (
                      bgimg.artist
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <div className={`${style.embiggen} ml-auto`}>
            <NavLink to="/help/support">
              Rogue Trader &trade; and &copy; {config.year}. All Rights Reserved.
              Privacy Policy &amp; Terms of Service
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfile
