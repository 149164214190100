import React, { useCallback } from 'react'
import { R_USER } from '../reducer'
import { Error, badPassword } from 'components/Signon/password'
import { Link } from 'tools/Links'
import paths from 'constants/paths'

export const passwordStyle = { width: '20rem' }
export const defaultFactors = { federated: undefined, password: undefined }

function badCode({ code }, isAuthed) {
  if (code <= 0) {
    if (isAuthed) {
      return <Error>Missing a reset code / previous password</Error>
    }
    return <Error>Missing a reset code</Error>
  }
  return undefined
}

export function savePassword(dispatch, mutation, user, isAuthed) {
  const variables = {
    new: user.passform.password1
  }
  if (isAuthed) {
    variables.current = user.passform.code
  } else {
    variables.code = user.passform.code
  }
  mutation({
    variables,
    update(cache, { data: { changePassword } }) {
      if (changePassword.success) {
        if (isAuthed) {
          dispatch({
            type: R_USER.CHANGE_PASSFORM,
            done: <div className="green">Password updated!</div>
          })
          // redirect to /signout
        } else {
          dispatch({
            type: R_USER.CHANGE_PASSFORM,
            done: (
              <div>
                <div className="green mb3 b">Password updated!</div>
                You can now{' '}
                <Link to={paths.signon}>
                  <b>Sign In</b>
                </Link>
              </div>
            )
          })
        }
      } else {
        // easier for us to enrich the text this way
        if (changePassword.reason === 'code') {
          dispatch({
            type: R_USER.CHANGE_PASSFORM,
            merge: {
              passMsg: (
                <div>
                  <div className="red b mb3">Unable to change password.</div>
                  Are you sure the code is correct and has not expired? Do you
                  want to{' '}
                  <Link className="b" to={paths.password}>
                    Request a new Reset Code?
                  </Link>
                </div>
              )
            }
          })
        } else {
          dispatch({
            type: R_USER.CHANGE_PASSFORM,
            merge: { passMsg: changePassword.reason }
          })
        }
      }
    }
  })
}

export function passwordChange(dispatch, ev, key, form, isReady) {
  let value = ev.target.value.trim()
  let change = undefined
  switch (key) {
    case 'password1':
      change = { word: value, compare: form.password2, code: form.code }
      break
    case 'password2':
      change = { word: value, compare: form.password1, code: form.code }
      break
    case 'code':
      change = { code: value, word: form.password1, compare: form.password2 }
      break
    default:
      break
  }
  dispatch({
    type: R_USER.CHANGE_PASSFORM,
    merge: isReady(change, key, value)
  })
}

export function useReady(isAuthed) {
  return useCallback(
    (change, key, value) => {
      const passError = badPassword(change.word, change.compare)
      const codeError = badCode(change, isAuthed)
      return {
        good: !passError && !codeError,
        passMsg: passError || codeError,
        [key]: value
      }
    },
    [isAuthed]
  )
}
