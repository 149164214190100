import { GoogleOAuthProvider } from '@react-oauth/google'
import config from 'constants/config'
import React, { useContext } from 'react'
import NotifyContext, { notify } from 'tools/Notify/resolver'

function SignonProvider({ children }) {
  const [, notifyDispatch] = useContext(NotifyContext)
  return (
    <GoogleOAuthProvider
      clientId={config.auth.google.appId}
      onScriptLoadError={(error) => {
        notify(notifyDispatch, {
          content: 'Google Signin blocked by browser extension!'
        })
      }}
    >
      {children}
    </GoogleOAuthProvider>
  )
}

export default SignonProvider
