import React, { useContext, useCallback, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { ControlIcon } from 'tools/Controls'
import { ICON } from 'constants/icons'
import { Navigator, NavTabs } from 'tools/NavTabs'
import notifier from 'tools/Notify/notifier'
import { useParams } from 'react-router-dom'
import { GlobalContext } from 'reducer/global'
import { useQuery } from '@apollo/client'
import { CHG } from 'tools/Input'

/*

Browse — with <Provider> but not <Loader>
Create — with <Provider>, <Loader>, but not <Editor
Show — with <Provider> and <Loader> but not <Editor> nor tabs
NoTabEdit — with <Provider>, <Loader>, and <Editor> but not tabs

With none of the above it is <Provider>, <Loader>, <Editor>, <NavTabs>

(note: you can also use NavTabs tab option `tab.hideTab=true` for a
similar full-screen experience as NoTabEdit, but with <NavTabs>)

*/

export const defaultFrame = {
  tabs: undefined, // navTabs config
  Context: undefined, // <Context> func [required] —
  Provider: undefined, // <Provider> func [required] -
  Browse: undefined, // <Component> [optional]
  Show: undefined, // <Component> [optional]
  NoTabEdit: undefined, // Editing without using Tabs
  Create: undefined, // <Create> [optional]
  DO_LOAD: undefined, // flag [required] — reducer flag to use for setting
  DO_RESET: undefined, // flag [required] - reducer flag for resetting state
  DO_DELETE: undefined, // flag — reducer flag for deleting from state
  DO_SAVE: undefined, // flag — reducer for updating
  DO_MERGE: undefined, // flag [required] — reducer flag for merging changes
  LOAD: undefined, // graphql [required] — graphql for loading record
  SAVE: undefined, // graphql [required] — for upserting record
  queryName: undefined, // [required] name of the query returned from graphql
  componentName: undefined, // [required] name of mutation component
  loadResultKey: 'result', // load result key
  saveResultKey: 'result', // save result key
  oneLoadResult: true, // a single result or a list?
  targetKey: 'targetId', // required
  noTarget: undefined, // run this function if a target cannot be found
  tabKey: 'tabId',
  loadedWait: true,
  controlView: undefined,
  controlBack: undefined,
  pathKey: 'shortId',
  basePath: '', // paths[key] result
  tabShow: 'show',
  tabCreate: 'create'
  // debug: true // — optional to enable debugging on this frame
}

////////////////////////////////////////////////////////////////////////////////
export function Editable({ frame, ...pass }) {
  const { Provider } = frame
  return (
    <Provider>
      <EditableWrap frame={{ ...defaultFrame, ...frame }} {...pass} />
    </Provider>
  )
}

export function EditableWrap({ frame, loaderVars = {} }) {
  const params = useParams()
  const targetId = params[frame.targetKey]
  const tabId = params[frame.tabKey]
  let target = targetId !== '_' ? targetId : undefined
  const { Browse, Context, tabShow, tabCreate, debug, DO_RESET } = frame
  const [state, dispatch] = useContext(Context)
  const [glob] = useContext(GlobalContext)
  const history = useHistory()
  const pathId = state[frame.pathKey]
  const id = state.id

  useEffect(() => {
    if (!target && frame.noTarget) {
      const newTarget = frame.noTarget(params, state, glob)
      if (newTarget) {
        history.replace(newTarget)
      }
    }
  }, [frame, params, state, glob, target, history, tabId])

  useEffect(() => {
    if (DO_RESET && id && target !== id && target !== pathId) {
      dispatch({ type: DO_RESET })
    }
  }, [dispatch, target, id, pathId, DO_RESET])

  debug &&
    console.log('FRAME ' + frame.queryName, { target, targetId, tabId, frame })
  if (!target && Browse && tabId !== tabShow && tabId !== tabCreate) {
    return <Browse action={tabId || 'view'} />
  }

  const { Show, Create, NoTabEdit } = frame

  return (
    <Loader targetId={target} frame={frame} variables={loaderVars}>
      {Show && (!tabId || tabId === tabShow) ? (
        <Show targetId={target} />
      ) : Create && frame.SAVE && tabId === tabCreate ? (
        <Editor frame={frame} create={true}>
          <Create />
        </Editor>
      ) : NoTabEdit ? (
        <Editor frame={frame} targetId={target}>
          <NoTabEdit />
        </Editor>
      ) : !frame.SAVE ? (
        <Navigator config={frame.tabs}>
          <TabbedInner frame={frame} targetId={target} />
        </Navigator>
      ) : (
        <Editor frame={frame} targetId={target}>
          <Navigator config={frame.tabs}>
            <TabbedInner frame={frame} targetId={target} />
          </Navigator>
        </Editor>
      )}
    </Loader>
  )
}

////////////////////////////////////////////////////////////////////////////////
function doDispatch(dispatch, type, vars, value, key, one) {
  if (!type) {
    console.log('NO HANDLER FOR DISPATCH TYPE!', { vars, value, key, one })
  } else {
    if (key) {
      value = value[key]
    }
    if (one === false) {
      value = value[0]
    }
    value._loaded = true
    // console.log("<doDispatch>", {type, key, value, vars})
    dispatch({ type, ...vars, key, value })
  }
}

export function Loader({
  targetId = undefined,
  frame,
  children,
  variables: vars = {}
}) {
  const {
    LOAD,
    DO_LOAD,
    DO_MERGE,
    Context,
    oneLoadResult: one = false,
    queryName,
    fetchPolicy = 'cache-and-network',
    loadResultKey: key = 'result',
    debug
  } = frame
  const [, notify] = useContext(notifier.context)
  const [state, dispatch] = useContext(Context)
  const [{ user }] = useContext(GlobalContext)

  const qname = queryName // ? queryName : one ? type : type + 's'

  const variables = { id: targetId, ...vars, ...(state._LoaderVars || {}) }
  debug &&
    console.log('<Loader>', {
      fetchPolicy,
      skip: !variables?.id,
      variables,
      targetId,
      LOAD
    })
  const { refetch } = useQuery(LOAD, {
    variables,
    skip: !variables?.id,
    fetchPolicy,
    onCompleted(result) {
      debug &&
        console.log('<Loader> onCompleted', {
          result: { ...result },
          qname,
          key,
          id: targetId,
          variables,
          frame
        })
      if (result) {
        result = result[qname]
        if (key === false) {
          doDispatch(dispatch, DO_LOAD, { user }, result, key, one)
        } else {
          if (!result.success) {
            notifier.error(notify, result.reason)
          } else {
            doDispatch(dispatch, DO_LOAD, { user }, result, key, one)
          }
        }
      }
    }
  })

  useEffect(() => {
    dispatch({ type: DO_MERGE, value: { refetch } })
  }, [refetch, DO_MERGE, dispatch])

  // TODO: have DO_LOAD set _loaded, and even link into frame a <Loader> setting
  // this will require reviewing all reducers to verify they set _loader which
  // isn't ideal... perhaps inject result._loaded=true, and verify it makes it
  // through...
  // TODO: Sometimes was causing stack trace dumps :(  Debug later
  // if (!loadedWait || state._loaded) {
  return children
  // } else {
  //   return null
  // }
  // return children
}

////////////////////////////////////////////////////////////////////////////////
export function Editor({
  frame,
  create = false,
  children,
  targetId = undefined
}) {
  const { Context, SAVE, DO_MERGE } = frame
  const params = useParams()
  const [state, dispatch] = useContext(Context)
  const [{ user }] = useContext(GlobalContext)
  const history = useHistory()
  const [mutation] = useMutation(SAVE)
  const id = state.id
  const onSave = useCallback(
    (
      {
        token = undefined,
        orig = undefined,
        meta = {},
        cmeta = {},
        dirty = true,
        component = undefined,
        componentId = true
      },
      value,
      good,
      bad
    ) => {
      const {
        DO_LOAD,
        DO_SAVE,
        DO_DELETE,
        SAVE,
        queryName,
        componentName,
        basePath,
        debug,
        pathKey
      } = frame
      component = component || componentName || false
      debug &&
        console.log('<Editor>', {
          orig,
          component,
          value,
          dirty,
          token,
          meta,
          cmeta,
          SAVE
        })
      if (!dirty) {
        good && good()
      } else if (orig !== value && dirty) {
        let variables
        if (component !== false) {
          if (componentId) {
            variables = { [component]: { id, [token]: value, ...cmeta }, ...meta }
          } else {
            variables = { id, [component]: { [token]: value, ...cmeta }, ...meta }
          }
        } else {
          variables = { id, [token]: value, ...meta }
        }
        debug && console.log(`<Editor> SAVE ${queryName} variables`, variables)
        mutation({
          variables,
          update(cache, { data }) {
            // or saveResultKey?
            const res = data[Object.keys(data)[0]]
            debug &&
              console.log(`<Editor> SAVE ${queryName} result`, {
                data,
                res,
                frame
              })
            if (res.success === false) {
              bad && bad(res.reason)
              return
            }

            let value = res.result
            if (value === undefined && component && res[component]) {
              value = res[component]
            }

            doDispatch(
              dispatch,
              res.deleted ? DO_DELETE : DO_SAVE || DO_LOAD,
              { user, component },
              value
            )

            // dispatch({ type: DO_LOAD, value: result.result })

            const paramId = params[frame.targetKey]
            if (pathKey !== false && paramId !== value[pathKey]) {
              if (frame.tabEdit) {
                history.replace(`${basePath}/${value[pathKey]}/${frame.tabEdit}`)
              } else if (frame?.tabs?.path) {
                history.replace(
                  frame.tabs?.path(
                    {
                      tabId: params[frame.tabKey],
                      frame,
                      params: { ...params, targetId: value[pathKey] }
                    },
                    value,
                    basePath
                  )
                )
              } else {
                history.replace(
                  `${basePath}/${value[pathKey]}/${params[frame.tabKey]}`
                )
              }
            }
            good && good(CHG.SAVED, value)
          }
        })
      }
    },
    [dispatch, mutation, history, id, frame, params, user]
  )
  useEffect(() => {
    dispatch({ type: DO_MERGE, value: { onSave } })
  }, [onSave, dispatch, DO_MERGE])

  return children
}

////////////////////////////////////////////////////////////////////////////////
function TabbedInner({
  frame: {
    Context,
    basePath,
    tabs: { path },
    controlView,
    controlClose
  },
  targetId
}) {
  const [state] = useContext(Context)
  const params = useParams()
  // useTabs({ tabs: state.tabs, param: 'tabId', props: state.tabProps })
  // const dest = path
  //   ? (props) => path(props, state, basePath)
  //   : ({ tabId }) => `${basePath}/${targetId}/${tabId}`

  return (
    <NavTabs
      controls={
        <div className="flex">
          {controlView ? (
            <ControlIcon
              icon={ICON.view}
              className="button primary"
              to={controlView(targetId)}
            />
          ) : null}
          {controlClose ? (
            <ControlIcon
              icon="fas fa-times"
              to={controlClose({ targetId, state, params })}
            />
          ) : null}
        </div>
      }
    />
  )
}

export default Editable
