import {
  authError,
  asyncStartValidate,
  authDebug,
  AUTHX_ACTIONS
} from '../../utils/signon/'

export function doSignOnLocal({
  signup,
  handle,
  password,
  state,
  status,
  dispatch
}) {
  authDebug('[Signon].doSignOnLocal()', '')

  dispatch({ type: AUTHX_ACTIONS.SIGNING_IN })
  let vars = {
    signup,
    factor: 'email', // in the future: let people choose factors to auth with
    handle,
    password,
    email: ''
  }

  if (signup) {
    vars.email = vars.handle
  }

  if (vars.handle.length === 0) {
    authError({ dispatch, msg: 'Please provide an email address!' })
    return Promise.resolve({ aborted: true })
  }
  if (vars.password.length === 0) {
    authError({ dispatch, msg: 'Please provide a password!' })
    return Promise.resolve({ aborted: true })
  }

  return asyncStartValidate({ state, status, vars, dispatch })
}
