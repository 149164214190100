import React from 'react'
import './styles/index.scss'
import App from './App'
import { createRoot } from 'react-dom/client'
// import * as serviceWorker from './utils/serviceWorker'
// import log from 'utils/log'
import config from 'constants/config'
import { GlobalProvider } from 'reducer/global'
import { PlayerProvider } from 'reducer/player'
import { NotifyProvider } from 'tools/Notify/resolver'
import AuthProvider from 'components/Signon/Provider'

// log.event({ category: 'LoadApp', action: 'passive' })
console.log(`BUILD=${config.build} APP=${config.url.app}`)
const container = document.getElementById('root')
const root = createRoot(container)

// only include high level providers and configuration things here
root.render(
  <GlobalProvider>
    <NotifyProvider>
      <AuthProvider>
        <PlayerProvider>
          <App />
        </PlayerProvider>
      </AuthProvider>
    </NotifyProvider>
  </GlobalProvider>
)

// serviceWorker.unregister()
