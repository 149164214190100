import React, { useContext } from 'react'
import EmailEdit from './EmailEdit'
import UserContext from '../reducer'
import IdentifiedUser from 'tools/IdentifiedUser'
import EmailPrefs from '../EmailPrefs'
import { ExtLink } from 'tools/Links'

function Settings() {
  const [user] = useContext(UserContext)

  return (
    <IdentifiedUser>
      <div className="pa4 mb4">
        <div className="f3 heading mb3">
          <b>Please Note!</b>
        </div>
        <div className="mb4 i">
          RogueTrader is part of{' '}
          <ExtLink to="https://revenant.studio">
            Revenant Creative Studio's
          </ExtLink>{' '}
          network, so these settings are global across all systems, and email
          messages come from
          <code className="b ml2">@revenant.studio</code>.
        </div>

        <label className="f5 b db mb3 heading">Addresses</label>
        <EmailEdit user={user} />
        <EmailPrefs />
      </div>
    </IdentifiedUser>
  )

  // <label className="f5 b db mt4 mb3 heading">Phone</label>
  // <PhoneEdit user={user} />
  // TODO: LOOP BACK TO THIS

  // </div>
  // {showOkay ? (
  //   <Modal viewState={[showOkay, setShowOkay]} width="fw-50">
  //     <div
  //       className="flex-center flex-column fo-text-white"
  //       style={{ minHeight: '75vh' }}
  //     >
  //       <div>Email Verified!</div>
  //       <div className="mt3">{showOkay}</div>
  //       <button
  //         onClick={() => {
  //           setShowOkay('')
  //           history.push(history.location.path)
  //         }}
  //         className="mt3"
  //       >
  //         Okay
  //       </button>
  //     </div>
  // </Modal>
  // ) : null}
  // </div>
  // )
}

export default Settings
