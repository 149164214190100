import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Input from 'tools/Input'
import Profile from './Profile'
// import Report from './Report'

export function PublicPlayer() {
  const target = useState('')
  return (
    <div className="mh3 mt3 pt2 relative fo-frame">
      <PlayerProfile inputState={target} />
    </div>
  )
}

function PlayerProfile({
  inputState = undefined,
  placeholder = 'Player Handle',
  handle = undefined,
  indent = true,
  setFound = undefined,
  inlineRow = null,
  ...props
}) {
  const params = useParams()
  const target = (inputState ? inputState[0] : handle) || params.player
  // const [report, setReport] = useState(false)
  return (
    <div className="pa2">
      {inputState ? (
        <>
          <div className="flex justify-center items-center w-100">
            <Input
              icons={false}
              valState={inputState}
              placeholder={placeholder}
              onSave={() => {}}
              className="w-50"
              wrapClass=""
              inputClass=""
              autoFocus={true}
            />
          </div>
          <div className="mv4">
            <Profile {...props} target={target} setFound={setFound} />
          </div>
        </>
      ) : (
        <Profile {...props} target={target} />
      )}
    </div>
  )
}
// {report ? (
//   <Report target={target} />
// ) : (
// {inlineRow ? (
//   inlineRow
// ) : (
//   <div className="ml4">
//     <button className="button pv1" onClick={() => setReport(!report)}>
//       {report ? 'VIEW' : 'REPORT'}
//     </button>
//   </div>
// )}

export default PlayerProfile
