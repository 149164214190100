import React, { useState } from 'react'

function EditEmail({ user }) {
  const deletable = user.emails.length > 1

  return (
    <div className="w-100 ba b--theme-faint br2 mb2 input noedit pa2">
      {user.emails.map((email) => (
        <ShowEmail
          key={email.id}
          email={email}
          deletable={deletable}
          user={user}
        />
      ))}
      <AddEmail user={user} />
    </div>
  )
}

function ShowEmail({ email, deletable, user }) {
  const [check, setCheck] = useState(false)
  return (
    <div
      key={email.id}
      className="hover-hilite flex justify-between items-center mb2"
    >
      {email.address}
      <div className={`${email.verified ? 'green' : 'red'} ml2 f7 mr-auto`}>
        {email.verified ? (
          'verified'
        ) : (
          <>
            <button
              className="button-clear-light pa0 ph2"
              onClick={() => {
                user.onSave(
                  {
                    component: 'email',
                    cmeta: { verify: true },
                    token: 'id',
                    componentId: false
                  },
                  email.id
                )
                setCheck(true)
              }}
            >
              resend verify email
            </button>
            {check ? <i className="ml2 fas fa-check green" /> : null}
          </>
        )}
      </div>
      {deletable ? (
        <i
          className="fas fa-trash hover-hilite pa1 br0 pointer f7"
          onClick={() =>
            user.onSave(
              {
                component: 'email',
                meta: { action: 'REMOVE' },
                token: 'id',
                componentId: false
              },
              email.id
            )
          }
        />
      ) : null}
    </div>
  )
}

function AddEmail({ user }) {
  const [adding, setAdding] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [msg, setMsg] = useState('')

  if (adding) {
    return (
      <>
        <div className="flex-items">
          <input
            className="w-100"
            value={newEmail}
            autoFocus={true}
            placeholder="New Email Address"
            onChange={(ev) => {
              setNewEmail(ev.target.value.trim())
            }}
          />
          <button
            className="button pa2 ml2 ph4"
            onClick={() =>
              user.onSave(
                { component: 'email', componentId: false, token: 'email' },
                newEmail,
                () => {
                  setAdding(false)
                  setNewEmail('')
                  setMsg('')
                },
                (m) => {
                  setMsg(m)
                }
              )
            }
          >
            Add
          </button>
        </div>
        {msg ? <div className="mt3 red">{msg}</div> : null}
      </>
    )
  }
  return (
    <button
      className="button-grayblue f6 pa2 mb0 mt1 ph4 tl"
      onClick={() => setAdding(true)}
    >
      <i className="fas fa-plus mr2" />
      Add Email
    </button>
  )
}

export default EditEmail
