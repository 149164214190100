// import React from 'react'

export function enrichCharBrands(chars) {
  let existing = {}
  return chars.map((char) => {
    char = { ...char }
    let name = char.name.toUpperCase()
    let brand = name.slice(0, 1)
    if (existing[brand]) {
      let parts = name.split(/\s+/)
      if (parts.length > 1) {
        brand = parts[0].slice(0, 1) + parts[1].slice(0, 1)
      } else {
        brand = name.slice(0, 2)
      }
      if (existing[brand]) {
        const nums = name.replace(/[^0-9]+/, '')
        if (nums) {
          brand = brand.slice(0, 1) + nums.slice(0, 1)
        }
        let x = 1
        while (existing[brand] && x <= 9) {
          brand = `${name.slice(0, 1)}${x}`
          x++
        }
        x = 1
        while (existing[brand] && x <= 9) {
          brand = `${name.slice(0, 2)}${x}`
          x++
        }
        // hopefully we have no conflicts at this point.  if so... ohwell
      }
    }
    existing[brand] = true
    char.brand = brand
    return char
  })
}

export default enrichCharBrands
