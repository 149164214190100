const mod = {
  adminSummary: '/adm/summary',
  adminUsers: '/adm/users',
  dashboard: '/dashboard',
  help: '/help',
  helpRoute: '/help/:tabId?',
  items: '/items',
  itemsRoute: '/items/:tabId?',
  messageRoute: '/messages/',
  password: '/pwreset',
  playerRoute: '/p/:player?',
  preferences: '/prefs/_/contact',
  priceCheck: '/items/estimate',
  prices: '/items/check',
  rulesRoute: '/rules/',
  signon: '/signon',
  signout: '/signout',
  social: '/social',
  socialRoute: '/social/:tabId?/:opt?',
  supportRoute: '/support/',
  tools: '/tools',
  toolsRoute: '/tools/:tabId?',
  trade: '/trade',
  tradeRoute: '/trade/:tabId?',
  user: '/prefs',
  userPublic: '/u',
  userPublicRoute: '/u/:targetId?',
  userRoute: '/prefs/:targetId?/:tabId?/:code?'
}

export default mod
