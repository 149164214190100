import React, { useReducer, createContext } from 'react'
import { ITEM_COLUMNS, sortItems } from 'utils/item'

export const PLAYER_STATE = {
  RESET: 0,
  INV_SET_ALL: 1,
  INV_ADD_ONE: 2,
  INV_CHG_ONE: 3,
  PREFS_SET: 4
}

// as a function so nested objects are always unique
function defaultState() {
  return {
    char: {},
    prefs: {
      itemSort: { cols: ITEM_COLUMNS, reverse: false },
      inv: { branches: [] }
    },
    taxon: {},
    inv: []
  }
}

export const reducer = (state, action) => {
  let valueId
  switch (action.type) {
    case PLAYER_STATE.INV_DEL_ONE:
      valueId = action.value.id
      return { ...state, inv: state.inv.filter((item) => item.id !== valueId) }
    case PLAYER_STATE.INV_CHG_ONE:
      // map inline, do not redo sort
      valueId = action.value.id
      return {
        ...state,
        inv: state.inv.map((item) => {
          if (item.id === valueId) {
            return action.value
          } else {
            return item
          }
        })
      }
    case PLAYER_STATE.INV_ADD_ONE:
      // add, then sort again
      return {
        ...state,
        inv: sortItems(state.inv.concat(action.value), state.prefs)
      }
    case PLAYER_STATE.INV_SET_ALL:
      return { ...state, inv: sortItems(action.value, state.prefs) }
    case PLAYER_STATE.PREFS_SET:
      return { ...state, prefs: { ...state.prefs, [action.key]: action.value } }

    case PLAYER_STATE.RESET:
      return defaultState()
    default:
      throw new Error(`no such action.type: ${action.type}!`)
  }
}

export const PlayerContext = createContext(null)

export function PlayerProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultState())
  return (
    <PlayerContext.Provider value={[state, dispatch]}>
      {children}
    </PlayerContext.Provider>
  )
}

export default PlayerContext
