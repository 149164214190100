import { NotifyContext, POPUP_MESSAGE } from './resolver'

const notifier = {
  notify(d, type, content) {
    d({ type: POPUP_MESSAGE, value: { type, content } })
  },
  error(d, content) {
    d({ type: POPUP_MESSAGE, value: { type: 'error', content } })
  },
  warn(d, content) {
    d({ type: POPUP_MESSAGE, value: { type: 'warn', content } })
  },
  info(d, content) {
    d({ type: POPUP_MESSAGE, value: { type: 'info', content } })
  },
  context: NotifyContext
}

export default notifier
