// import axios from 'axios'

// TODO: update to allow more variants, such as off-host and on-host
let host = window.location.hostname
let port = window.location.port
// let orig_port = ':' + port
let tls = true
if (process.env.NODE_ENV === 'development') {
  port = ':4000'
  tls = false
} else {
  host = 'revenant.studio'
  if (
    (port === '80' && window.location.protocol === 'http') ||
    (port === '443' && window.location.protocol === 'https')
  ) {
    port = ''
  } else {
    port = ':' + port
  }
}

const config = {
  year: new Date().getFullYear(),
  name: 'Revenant Creative Studio',
  lane: 'p',
  build: 'latest',
  ga_tag: 'G-MF8CW858H0',
  nav_show: {
    icon: true,
    text: false
  },
  url: {
    http: `http${tls ? 's' : ''}://${host}${port}`,
    ws: `ws${tls ? 's' : ''}://${host}${port}/socket`,
    app: `http${tls ? 's' : ''}://${host}${port}`, // @APP@
    graphql: '/graphql',
    authapi: '/authx/v1/api/',
    pub: `https://i.revenant.studio/`
  },
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '370077008372-k4fvmcb357sdjjeu8q8hdnui00aphitq.apps.googleusercontent.com'
    }
  }
}

// // TODO: move to global reducer
// console.log(
//   `getting http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`
// )
// axios
//   .get(`http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`)
//   .then(({ data }) => {
//     config.name = data.name
//     config.name_short = data.name_short
//     config.name_an = data.name_an
//     config.name_legal = data.name_legal
//   })

export default config
