import React, { useContext, useState, useEffect } from 'react'
import GlobalContext, { R_GLOBAL } from 'reducer/global'
import styles from './CompareTimes.module.scss'
import moment from 'moment'

// #f2c45e
const MULT = 10
const BAR_HEIGHT = 2 // not multiplied
const LABEL_EVERY = 3
const LABEL_Y = 10 // is multiplied
const LABEL_X = -9 // is multiplied
const COLOR = '#f2c45e'
// const COLOR_ALPHA = '#f2c45e88'
const HOURS = 24

function PathBoxes({
  range,
  rangeY,
  className = '',
  isMine = false,
  toggleHour,
  ...args
}) {
  return range.map((on, hour) => {
    if (!on && !isMine) {
      return null
    }

    return (
      <PathBox
        key={hour}
        hour={hour}
        rangeX={[hour, hour + 1]}
        rangeY={rangeY}
        toggleHour={toggleHour}
        isMine={isMine}
        className={`${className} ${on ? '' : styles.off}`}
        {...args}
      />
    )
  })
}

function PathBox({
  rangeX,
  rangeY,
  className = '',
  toggleHour,
  hour,
  isMine = false,
  ...args
}) {
  const top = rangeY[0] * MULT
  const bot = rangeY[1] * MULT
  const begin = rangeX[0] * MULT
  const end = rangeX[1] * MULT

  return (
    <path
      d={`M${begin} ${top} L${end} ${top} L${end} ${bot} L${begin} ${bot} L${begin} ${top}`}
      className={`${styles.box} ${className}`}
      onClick={() => (isMine ? toggleHour(hour) : null)}
      {...args}
    />
  )
}

function HourLine({ hour, rangeY, ...opts }) {
  const top = rangeY[0] * MULT
  const bot = rangeY[1] * MULT
  const className = opts.className ? opts.className : styles.line
  return (
    <line
      x1={hour * MULT}
      y1={bot}
      x2={hour * MULT}
      y2={top}
      className={className}
    />
  )
}

// from sets to hours
function hourSetExpand(sets) {
  const inSets = (x) => sets.filter(([a, b]) => a <= x && x <= b).length
  return Array.from(Array(HOURS)).map((a, x) => (inSets(x) ? 1 : 0))
}
function hourSetCollapse(hours) {
  let sets = []
  let begin = -1
  let x = 0
  for (; x < hours.length; x++) {
    if (hours[x]) {
      if (begin === -1) {
        begin = x
      }
    } else {
      if (begin !== -1) {
        sets = sets.concat([[begin, x - 1]])
        begin = -1
      }
    }
  }
  if (begin !== -1) {
    sets = sets.concat([[begin, x]])
  }
  return sets
}

// breaks with india
function hourShiftLocal(hours, reverse = false) {
  const offset = new Date().getTimezoneOffset() / 60
  return rotate([...hours], reverse ? -offset : offset)
}

// move to utils
function rotate(arr, count) {
  if (count < 0) for (let x = 0; x > count; x--) arr.unshift(arr.pop())
  else for (let x = 0; x < count; x++) arr.push(arr.shift())
  return arr
}

function Label({ hour }) {
  let label = ''
  if (hour === 12) {
    label = `${hour}pm`.padStart(2, '0')
  } else if (hour > 12) {
    label = `${hour - 12}pm`.padStart(2, '0')
  } else {
    label = `${hour}am`.padStart(2, '0')
  }
  return (
    <text
      key={hour}
      x={hour * MULT + LABEL_X}
      y={LABEL_Y}
      fill={COLOR}
      className={styles.text}
    >
      {label}
    </text>
  )
}

////////////////////////////////////////////////////////////////////////////////
function CompareTimes({ target, isMine, updateMutation }) {
  const [tick, setTick] = useState(0)
  const [{ user }, dispatch] = useContext(GlobalContext)
  let thours = (target.data || []).filter((data) => data.type === 'available')
  if (thours.length > 0) {
    thours = thours[0]
  } else {
    thours = {}
  }
  const hours = hourShiftLocal(
    hourSetExpand(thours.id ? thours.value.available : [])
  )

  // this refreshes the "current time" if somebody is just stuck on the page
  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        setTick(tick + 1)
      }
    }, 60000)
    return () => {
      isMounted = false
    }
  })

  let args = {
    toggleHour: () => {}
  }

  if (isMine) {
    args = {
      ...args,
      toggleHour: (hour) => {
        const nhours = [...hours]
        nhours[hour] = hours[hour] ? 0 : 1
        const vars = {
          type: 'available',
          value: JSON.stringify({
            available: hourSetCollapse(hourShiftLocal(nhours, true))
          })
        }
        if (thours.id) {
          vars.id = thours.id
        }
        updateMutation({
          variables: { id: user.id, data: vars },
          update(cache, { data: { updateUser } }) {
            if (updateUser.success) {
              dispatch({
                type: R_GLOBAL.USER_SET,
                value: updateUser.result
              })
            }
          }
        })
        // setHours(nhours)
      },
      className: styles.viewer,
      isMine: true
    }
  }

  const mom = moment()
  const now = mom.hour() + mom.minute() / 60

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.avail}
      viewBox={`-1 0 ${HOURS * MULT + 2} ${BAR_HEIGHT * 2 * MULT}`}
    >
      <PathBoxes range={hours} rangeY={[BAR_HEIGHT, BAR_HEIGHT * 2]} {...args} />
      <HourLine
        hour={0}
        rangeY={[BAR_HEIGHT * (!(0 % LABEL_EVERY) ? 0.7 : 0.9), BAR_HEIGHT * 2]}
      />
      {hours.map((i, x) => {
        const hour = x + 1
        return (
          <HourLine
            key={hour}
            hour={hour}
            rangeY={[
              BAR_HEIGHT * (!(hour % LABEL_EVERY) ? 0.7 : 0.9),
              BAR_HEIGHT * 2
            ]}
          />
        )
      })}
      <HourLine
        hour={now}
        rangeY={[BAR_HEIGHT * 0.6, BAR_HEIGHT * 3]}
        className={styles.now}
      />
      {hours.map((i, x) => {
        const hour = x + 1
        return !(hour % LABEL_EVERY) && hour !== 0 && hour !== HOURS ? (
          <Label key={`hrlab-${hour}`} hour={hour} />
        ) : null
      })}
    </svg>
  )
}

export default CompareTimes
