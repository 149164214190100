import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { REQUEST_PASSWORD_RESET } from '../graphql'
import ResetCode from './ResetCode'
import GlobalContext from 'reducer/global'
import { UserContext, R_USER } from '../reducer'
import NewPassword from './NewPassword'
import { LoadingInline } from 'tools/Loader'

function ResetPassword() {
  const [gUser, dispatch] = useContext(UserContext)

  const form = gUser.passform
  const parsed = form._parsed

  const [{ user }] = useContext(GlobalContext)
  const { code } = useParams()

  useEffect(() => {
    if (!parsed && code) {
      dispatch({ type: R_USER.CHANGE_PASSFORM, merge: { code, _parsed: true } })
    }
  }, [code, parsed, dispatch])

  return (
    <div className="fo-frame max-view-page mt4 mb5 pa4">
      <div className="heading f3 mb3 ">Password Reset</div>
      {form.done ? (
        form.done
      ) : (
        <>
          If you have forgotten your password or you are signed in with Federated
          Identity (i.e. google or other), you can request a password reset. This
          will send you an email with a reset code, which you can use here to
          change your password.
          <div className="mt4">
            {!code ? (
              <EmailInput />
            ) : user.isIdentified ? (
              <>You are currently signed in. Sign out first!</>
            ) : (
              <>
                <ResetCode hasPassword={false} isAuthed={user.isAuthed} />
                {form.code ? <NewPassword isAuthed={user.isAuthed} /> : null}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

function EmailInput() {
  const [msg, setMsg] = useState(undefined)
  const [gUser, dispatch] = useContext(UserContext)
  const email = gUser.passform.email || ''
  const [request] = useMutation(REQUEST_PASSWORD_RESET)
  const requestReset = useCallback(
    (email) => {
      if (email.length > 0 && email.includes('@')) {
        setMsg(<LoadingInline />)
        request({
          variables: { email },
          update: (cache, result) => {
            setMsg(
              <>
                Your request is submitted. You should receive a message in a few
                minutes with further instructions. If it does not appear soon,
                also check your Junk Mail folder.
              </>
            )
          }
        })
      } else {
        setMsg(<>Please provide a valid email address</>)
      }
    },
    [setMsg, request]
  )

  return (
    <>
      <div className="mt4 mb3">
        <label>Your Email Address</label>
      </div>
      <div className="mt2 flex-items">
        <input
          className="mr4 w-75"
          value={email}
          placeholder="Email Address"
          onChange={(ev) =>
            dispatch({
              type: R_USER.CHANGE_PASSFORM,
              merge: { email: ev.target.value.trim() }
            })
          }
        />
        <button
          className="button medium nowrap"
          onClick={() => requestReset(email)}
        >
          Request Reset Code
        </button>
      </div>
      <div className="mt3 i b">{msg}</div>
    </>
  )
}

export default ResetPassword
