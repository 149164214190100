import config from 'constants/config'
import { REQUIREMENTS } from 'components/Signon/password'

const defaultPassForm = {
  passMsg: REQUIREMENTS,
  password1: '',
  password2: '',
  code: ''
}
export const defaultUser = {
  id: '',
  age: 0,
  tabProps: {},
  settings: {},
  handle: '',
  name: '',
  verified: false,
  avatar: { url: '' },
  displayEmail: '',
  dataTypes: {},
  emails: [],
  phones: [],
  tagsD: {},
  data: [],
  subscriptions: [],
  toggles: {},
  // used during resetting passwords
  passform: defaultPassForm,
  allows: (x, y) => false,
  _normal: false
}

function normalizeUserData(state, value) {
  state.dataTypes = (value || []).reduce((a, d) => {
    a[d.type] = d
    return a
  }, {})
  state.toggles = state.dataTypes?.toggles?.value || {}
  return state
}

export function normalizeUserTags(state, value) {
  state.tagsD = (value || []).reduce((acc, tag) => {
    const current = acc[tag.type] || []
    // collapse usermap and tag
    acc[tag.type] = current.concat({
      ...tag,
      ...tag.tag,
      user_tag_id: tag.id
    })
    return acc
  }, {})
  return state
}

function normalizeUserEmails(state, value) {
  if (value && value.length > 0) {
    const vemails = value.filter((e) => e.verified)
    state.verified = false
    if (vemails.length > 0) {
      state.verified = true
    }
    // state.displayEmail = ''
    //   state.displayEmail = vemails[0].address
    // } else {
    //   state.displayEmail = value[0].address
    // }
    state.primaryEmail = value.find((email) => email.primary)
    if (!state.primaryEmail) {
      state.primaryEmail = value[0]
    }
    state.emails = value
  }
  return state
}

export function normalizeUserAvatar(state, value) {
  if (value.path) {
    state.avatar = { ...value, url: config.url.pub + value.path }
  }
  return state
}

export function normalizeUser(state, update = {}) {
  let user = { ...state, ...update }
  user = normalizeUserAvatar(user, user.avatar)
  user = normalizeUserTags(user, user.tags)
  user = normalizeUserData(user, user.data)
  user = normalizeUserEmails(user, user.emails)
  user.tabProps = {
    toggles: user.toggles
  }
  user.passform = { ...defaultPassForm }
  user.allows = (actor, action) => {
    return actor.id === user.id || actor.is('superadmin')
  }
  user.age = (Date.now() - new Date(user.insertedAt).getTime()) / 86400000
  user._normal = true
  return user
}

export function getUserData(user, group, token) {
  const grp = user.dataTypes[group] || { value: { [token]: '' } }
  return grp.value[token]
}

export function changeUserData(state, value, group, token) {
  const dataTypes = state.dataTypes
  const gdata = dataTypes[group] || { value: { [token]: '' } }

  return {
    ...state,
    dataTypes: {
      ...dataTypes,
      [group]: {
        ...gdata,
        value: {
          ...gdata.value,
          [token]: value
        }
      }
    }
  }
}

export function changePassForm(state, args) {
  const { merge, done } = args
  if (merge) {
    const passform = { ...state.passform, ...merge }
    return { ...state, passform }
  } else if (done) {
    return { ...state, passform: { ...defaultPassForm, done } }
  } else {
    throw new Error('unknown passform change')
  }
}
