import React, { useState, useContext } from 'react'
// import { useParams } from 'react-router-dom'
import { Inject } from 'tools/Page'
import GlobalContext from 'reducer/global'
import PlayerProfile from './PlayerProfile'
import { Navigator, normalizeTabs } from 'tools/NavTabs'
import paths from 'constants/paths'

// TODO: star positions can be more than one (Lucky)

const tabConfig = normalizeTabs(
  {},
  {
    root: paths.social,
    param: 'tabId',
    tabs: [
      {
        name: 'Profile',
        id: 'profile',
        Component: Profile
      },
      {
        name: 'Players',
        id: 'players',
        Component: Players
      },
      {
        name: 'Community',
        id: 'community',
        Component: Inject,
        props: { path: 'community.html' }
      }
    ]
  }
)

function Social() {
  return <Navigator config={tabConfig} />
}

function Players() {
  const handleState = useState('')
  return <PlayerProfile inputState={handleState} indent={false} />
}

export function Profile() {
  const [context] = useContext(GlobalContext)
  return <PlayerProfile handle={context.user.handle} user={context.user} />
}

export default Social
