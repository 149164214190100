// @ts-nocheck
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import Switch from 'react-switch'
import Input from 'tools/Input'
import { UPDATE_PERSON } from 'components/User/graphql'
import { iconMapHandle } from 'constants/icons'
import GlobalContext from 'reducer/global'
import { R_GLOBAL } from 'reducer/global'

////////////////////////////////////////////////////////////////////////////////
function IconicHandle({ platform, handle }) {
  const [icon, hresult] = iconMapHandle(platform, handle)
  return (
    <>
      <td>
        <i className={`${icon.icon} mr2`} />
      </td>
      <td>{hresult}</td>
    </>
  )
}

export function ListHandles({ player }) {
  return (
    <table>
      <tbody>
        {player.platforms.map(({ handles, platform }) => {
          let handle = player.handle
          if (handles.length > 0) {
            handle = handles[0]
          }
          return (
            <tr key={platform}>
              <IconicHandle platform={platform} handle={handle} />
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

////////////////////////////////////////////////////////////////////////////////
function PlatformToggle({ settings, makeChange, label, icon }) {
  // invert so it's on by default, even if not set
  const key = label.toLowerCase()
  const value = !!settings.platform[key]
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    setEnabled(value)
  }, [value])
  return (
    <div className="flex items-center mb2">
      <Switch
        onChange={() => {
          const value = !enabled
          setEnabled(value)
          if (value) {
            settings.platform[key] = true
          } else {
            delete settings.platform[key]
          }
          makeChange({ user: { settings: JSON.stringify(settings) } })
        }}
        checked={enabled}
        onColor="#F2C45E"
        offColor="#394247"
        height={12}
        width={30}
        className="mr2"
        activeBoxShadow="0 0 2px 3px white"
      />
      <i
        className={`fab fa-${icon} pl1 pr3 ${enabled ? 'fo-alt-yellow' : 'gray'}`}
      />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function InputHandle({
  settings,
  rogueHandle,
  makeChange,
  label,
  enabled = true,
  tok = ''
}) {
  let key
  if (tok) {
    key = tok
  } else {
    key = label.toLowerCase()
  }

  // support it as an array - in the future update to allow more than one
  let handle = settings.handles[key]
  if (handle) {
    handle = handle[0]
  } else {
    handle = ''
  }

  let disabled = enabled ? false : !settings.platform[key]
  let value
  // const upper = label.toUpperCase()
  return (
    <div>
      <label className="ttu">{label} HANDLE</label>
      <input
        ref={(node) => {
          value = node
        }}
        defaultValue={handle}
        disabled={disabled}
        onBlur={(e) => {
          const v = value.value
          if (v.length > 0) {
            settings.handles[key] = [v]
          } else {
            delete settings.handles[key]
          }
          makeChange({ user: { settings: JSON.stringify(settings) } })
        }}
        type="text"
        placeholder={rogueHandle || 'enter handle'}
        className={`w-100 ba b--gray br2 mb2 ${disabled ? 'noedit' : ''}`}
      />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function InputPlatforms({ settings, rogueHandle, makeChange }) {
  const pargs = { settings, rogueHandle, makeChange }
  if (!settings.platform) {
    settings.platform = {}
  }
  if (!settings.handles) {
    settings.handles = {}
  }
  return (
    <>
      <div className="mv2 flex items-start justify-between">
        <div className="mr1">
          <PlatformToggle icon="playstation" label="PS4" {...pargs} />
          <InputHandle label="PS4" {...pargs} enabled={false} />
        </div>
        <div className="mh1">
          <PlatformToggle icon="xbox" label="Xbox" {...pargs} />
          <InputHandle label="Xbox" {...pargs} enabled={false} />
        </div>
        <div className="ml1">
          <PlatformToggle icon="windows" label="pc" {...pargs} />
          <InputHandle label="pc" {...pargs} enabled={false} />
        </div>
      </div>
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////
export function Handles() {
  const [handle, setHandle] = useState('')
  const [{ user }, dispatch] = useContext(GlobalContext)
  const [updateUserMutation] = useMutation(UPDATE_PERSON)
  const userId = user.id

  const makeChange = useCallback(
    (vars, next, reject) => {
      const variables = { id: userId, ...vars }
      updateUserMutation({
        variables,
        update(cache, { data: { updateUser } }) {
          if (updateUser.success) {
            dispatch({
              type: R_GLOBAL.USER_SET,
              value: updateUser.result
            })
          } else {
            reject(updateUser.reason)
          }
          if (next) {
            next(updateUser)
          }
        }
      })
    },
    [updateUserMutation, dispatch, userId]
  )

  const saveHandle = (args, value, resolve, reject) => {
    console.log({ resolve, reject })
    if (handle !== user.handle) {
      makeChange({ handle: { handle: value } }, resolve, reject)
    }
  }

  useEffect(() => {
    setHandle(user.handle)
  }, [user.handle])

  const props = {
    settings: user.settings,
    makeChange,
    rogueHandle: user.handle
  }

  return (
    <>
      <InputPlatforms {...props} />
      <div className="mv2">
        <label className="mb2">RogueTrader Handle</label>
        <span className="i f6">Your primary handle on this site</span>
        <Input
          valState={[handle, setHandle]}
          onSave={saveHandle}
          placeholder="Your Handle"
        />
      </div>
      <InputHandle
        label="roguetrader discord"
        tok="discord"
        {...props}
        enabled={true}
      />
      <InputHandle label="bethesda.net" {...props} enabled={true} />
      <InputHandle label="reddit" tok="reddit" {...props} enabled={true} />
    </>
  )
}
