import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_PERSON } from 'components/User/graphql'
import { ToggleDict as Toggle } from 'tools/Toggle'
import GlobalContext, { R_GLOBAL } from 'reducer/global'
import { strcmp } from '../../../utils/string'

const OPTIONS = {
  builder: 'Builder - I love to build!',
  PvP: 'PvP - My enemies tremble at my name',
  merchant: 'Merchant - I love to hunt and trade things!',
  raider: 'Raider - I love to run with teams through a raid',
  quester: 'Quester - The solo life of quests keeps me going',
  Roleplayer: 'Roleplayer - I never break character, well, mostly never'
}

export function PlayPrefs() {
  const [{ user }, dispatch] = useContext(GlobalContext)
  const [updateUserMutation] = useMutation(UPDATE_PERSON)

  // TODO: dry out w/Prefs
  const makeChange = (vars, next) => {
    updateUserMutation({
      variables: { id: user.id, user: vars },
      update(cache, { data: { updateUser } }) {
        if (updateUser.success) {
          dispatch({
            type: R_GLOBAL.USER_SET,
            value: updateUser.result
          })
        }

        if (next) {
          next(updateUser)
        }
      }
    })
  }

  const args = {
    settings: user.settings,
    value: false,
    invert: true,
    onChange: (a) => {
      const settings = user.settings
      settings.playstyle = a
      makeChange({ settings: JSON.stringify(settings) })
    }
  }
  return (
    <div className="">
      {Object.keys(OPTIONS)
        .sort(strcmp)
        .map((key) => {
          return (
            <Toggle key={key} {...args} subkey="playstyle" keyword={key}>
              {OPTIONS[key]}
            </Toggle>
          )
        })}
    </div>
  )
}

export function ListPlayPrefs({ player }) {
  return (
    <div>
      {player.playstyles.sort(strcmp).map((key) => {
        return <div key={key}>{OPTIONS[key]}</div>
      })}
    </div>
  )
}
