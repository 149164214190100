import React, { lazy, Suspense, useEffect, useContext } from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import paths from 'constants/paths'

import NotifyContext, { notify } from 'tools/Notify/resolver'
// import NotifyStore, { notify } from 'tools/Notify/resolver'

import GlobalContext, { R_GLOBAL } from 'reducer/global'
import { asyncRefreshToken, AUTHX_ACTIONS } from 'utils/signon'
import { LOAD_USER } from 'components/User/graphql'
// import { when } from 'utils/function'
import { LOAD_SYSTEMS } from 'constants/Systems'

import { LoadingOverlay } from 'tools/Loader'

import Signon from 'components/Signon'
import Background from 'components/Root/Background'
import Home from 'components/Home'
import Notify from 'tools/Notify'
import User from 'components/User'
import { Redirect } from 'tools/Page'
import { PublicPlayer } from 'components/Social/PlayerProfile'

const AdminUsers = lazy(() => import('components/Admin/Users'))
const AdminSummary = lazy(() => import('components/Admin/Summary'))
const Tools = lazy(() => import('components/Tools'))
const Help = lazy(() => import('components/Help'))
const Dashboard = lazy(() => import('components/Dashboard'))
const Messages = lazy(() => import('components/Messages'))
const Social = lazy(() => import('components/Social'))
const ResetPasswordNoAuth = lazy(() =>
  import('components/User/Account/ResetPasswordNoAuth')
)
const Trade = lazy(() => import('components/Trade'))
const Items = lazy(() => import('components/Items'))

const LOADING = <LoadingOverlay onlyFull={true} />

export function App() {
  const [state, dispatch] = useContext(GlobalContext)
  const [, notifyDispatch] = useContext(NotifyContext)

  // TODO: remove this
  // const [inventoryQueries, setInventoryQueries] = useState({})

  // initialize apollo
  useEffect(() => {
    if (!state.apolloInit) {
      dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
    }
  }, [state.apolloInit, dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { isAuthN, refresh } = state.authx
  useEffect(() => {
    if (refresh) {
      asyncRefreshToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in
  useEffect(() => {
    let isMounted = true
    if (isAuthN) {
      state.apollo
        .query({
          query: LOAD_USER,
          fetchPolicy: 'network-only',
          variables: { id: 'self' }
        })
        .then(({ data: { users } }) => {
          if (isMounted) {
            if (users.success) {
              dispatch({ type: R_GLOBAL.USER_SET, value: users.result })
            } else {
              console.log(users.reason)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return () => {
      isMounted = false
    }
  }, [isAuthN, state.apollo, dispatch])

  // convert authx errors to notify
  const authx_error = state.authx.error
  useEffect(() => {
    if (authx_error) {
      notify(notifyDispatch, { content: authx_error })
      dispatch({ type: AUTHX_ACTIONS.ERROR_CLEAR })
    }
  }, [authx_error, dispatch, notifyDispatch])

  // Fetch game system
  useEffect(() => {
    if (!state.gameSystem.id) {
      state.apollo
        .query({ query: LOAD_SYSTEMS, variables: { name: 'Fallout 76' } })
        .then(({ data: { gameSystems } }) => {
          dispatch({ type: R_GLOBAL.SET_GAMESYSTEM, value: gameSystems[0] })
        })
    }
  }, [dispatch, state.gameSystem, state.apollo])

  const isAuthAdmin = state.user.can('auth_admin')
  const isSummaryAdmin = state.user.can('summary_admin')

  return (
    <ApolloProvider client={state.apollo}>
      <Router>
        <Background>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path={paths.dashboard}>
              <Suspense fallback={LOADING}>
                <Dashboard />
              </Suspense>
            </Route>
            <Route path={paths.helpRoute}>
              <Suspense fallback={LOADING}>
                <Help />
              </Suspense>
            </Route>
            <Route path={paths.tradeRoute}>
              <Suspense fallback={LOADING}>
                <Trade />
              </Suspense>
            </Route>
            <Route path={paths.socialRoute}>
              <Suspense fallback={LOADING}>
                <Social />
              </Suspense>
            </Route>
            <Route path={paths.playerRoute}>
              <Suspense fallback={LOADING}>
                <PublicPlayer />
              </Suspense>
            </Route>
            <Route path={paths.userPublicRoute}>
              <Suspense fallback={LOADING}>
                <PublicPlayer />
              </Suspense>
            </Route>
            <Route path={paths.itemsRoute}>
              <Suspense fallback={LOADING}>
                <Items />
              </Suspense>
            </Route>
            <Route path={paths.messageRoute}>
              <Suspense fallback={LOADING}>
                <Messages />
              </Suspense>
            </Route>
            <Route path={paths.supportRoute}>
              <Redirect path="/help/support" />
            </Route>
            <Route path={paths.toolsRoute}>
              <Suspense fallback={LOADING}>
                <Tools />
              </Suspense>
            </Route>
            <Route path={paths.signon}>
              <Signon signout={false} />
            </Route>
            <Route path={paths.signout}>
              <Signon signout={true} />
            </Route>
            <Route path={paths.password}>
              <Suspense fallback={LOADING}>
                <ResetPasswordNoAuth />
              </Suspense>
            </Route>
            {state.user.isIdentified ? (
              <>
                <Route path={paths.userRoute}>
                  <User />
                </Route>
                {isAuthAdmin ? (
                  <Route path={paths.adminUsers}>
                    <Suspense fallback={LOADING}>
                      <AdminUsers />
                    </Suspense>
                  </Route>
                ) : null}
                {isSummaryAdmin ? (
                  <Route path={paths.adminSummary}>
                    <Suspense fallback={LOADING}>
                      <AdminSummary />
                    </Suspense>
                  </Route>
                ) : null}
              </>
            ) : null}
          </Switch>
          <Notify />
        </Background>
      </Router>
    </ApolloProvider>
  )
}

export default App
