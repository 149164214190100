import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import GlobalContext from 'reducer/global'
import { LOAD_PUBLIC_PERSON, UPDATE_PERSON } from 'components/User/graphql'
import { useQuery, useMutation } from '@apollo/client'
import NotSignedIn from 'tools/IdentifiedUser/NotSignedIn'
import { Handles, ListHandles } from './Handles'
import Loading from 'tools/Loader'
import { PlayPrefs, ListPlayPrefs } from './PlayPrefs'
// import InputInline from '../../InputInline'
import CharacterTabList from '../Character/TabList'
import CompareTimes from './CompareTimes'
import moment from 'moment'

function Profile({
  target = undefined,
  summary = false,
  user: passUser = undefined,
  setFound = (...args) => {}
}) {
  const [updateUserMutation] = useMutation(UPDATE_PERSON)
  const [context] = useContext(GlobalContext)

  const { loading, error, data } = useQuery(LOAD_PUBLIC_PERSON, {
    variables: {
      id: target
    },
    skip: !target
  })

  const found = (!loading && !error && data && data.publicUser.result) || false
  useEffect(() => {
    setFound(found)
  }, [found, setFound])

  if (!context.user.isIdentified) {
    return <NotSignedIn frame={false} />
  }

  if (loading) return <Loading />

  let profile = (data && data.publicUser && data.publicUser.result) || passUser
  if (profile && profile.id === context.user.id && passUser) {
    profile = { ...profile, isMe: true }
  }

  if (!profile) {
    if (target) {
      return <div className="pa4 f4 b">Unable to find player {target}</div>
    } else {
      return (
        <div className="pa4 f4 b">Please provide player handle to lookup</div>
      )
    }
  }

  const score = 3
  return (
    <>
      <div className="pr2">
        <div
          className="w-100 flex justify-between items-center ba b--fo-alt-yellow"
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className="ml2 ttu f4">{target}</div>
          <div>
            <Stars score={score} />
          </div>
          <div className="f7 ml2">{score} REPUTATION</div>
          <div
            className="flex justify-center f4 w-100 pa1"
            style={{ textTransform: 'uppercase' }}
          >
            <div className="gray ml2">Player Profile</div>
            <div className={`ml2 ${profile.verified ? 'gray' : 'red'}`}>
              {profile.verified ? (
                'VERIFIED'
              ) : profile.isMe ? (
                <Link
                  to="/prefs"
                  className="br2 ph2 button-clear-dark"
                  style={{
                    border: 'solid 1px var(--tc-red)',
                    color: 'var(--tc-red)'
                  }}
                >
                  NOT VERIFIED
                </Link>
              ) : (
                'NOT VERIFIED'
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="ma0 pa0 ph4-l pb4-l">
        <div className="ma4 flex-ns justify-around">
          <div className="w-50-ns mr2-ns">
            <label className="mb3">Platform and Handles</label>
            {profile.isMe ? (
              <>
                <Handles />
                <label className="mb3 mt4">Preferred Play Styles</label>
                <PlayPrefs />
              </>
            ) : (
              <>
                <ListHandles player={profile} />
                <label className="mb3 mt4">Preferred Play Styles</label>
                <ListPlayPrefs player={profile} />
              </>
            )}
          </div>
          <div className="mt4 mt0-ns w-50-ns ml2-ns">
            <div className="mb3 flex items-center">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={'https://player.roguetrader.com/' + target}
                className="button-clear-light br2 pa1"
              >
                <i className="fas fa-link f7 mr2" />
                player.roguetrader.com/{profile.handle}
              </a>
            </div>
            <div className="mb2">
              Profile is{' '}
              {moment(profile.insertedAt).fromNow().replace(' ago', ' old')}.
              {!profile.isMe ? (
                <span className="ml3">
                  Last Seen {moment(profile.lastSeen).fromNow()}.
                </span>
              ) : null}
            </div>
            <div>
              <label className="mb2 mt4">Online Game Times</label>
              {profile.isMe ? (
                <div className="mt3 f6 mb2 i">
                  Select your game time by clicking/tapping the hours you are
                  free):
                </div>
              ) : null}
            </div>
            <div className="tc">
              <CompareTimes
                isMine={profile.isMe}
                target={profile.isMe ? passUser : profile}
                updateMutation={updateUserMutation}
              />
            </div>
          </div>
        </div>

        {!summary ? (
          <div className="ma4">
            <label className="mb3">Characters</label>
            <CharacterTabList
              isMine={profile.isMe}
              player={profile.isMe ? passUser : profile}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

// thumbs-up thumbs-down
// gamepad
// tag == buyer
// store == seller
// people-carry == courier

// score is 0..{scale} 0.. 5 would show zero up to 5 stars
function Stars({ score }) {
  const stars = Array.from(Array(Math.floor(score))).map((_, i) => (
    <i key={i} className="fas fa-star f6 ml2" />
  ))

  if (Math.round(score * 2) % 2) {
    return (
      <>
        {stars.concat(
          <i key={stars.length} className="fas fa-star-half-alt f6 ml2" />
        )}
      </>
    )
  }
  return <>{stars}</>
}

/*
            <div className="">
            US/Mountain (1 hour before you)
            </div>
            <div className="mt2 flex">
            {days.map((d) => <div className="w-100 pa1 ba tc f7">{d}</div>)}
            </div>
            <div className="mt2 flex items-center justify-between">
            {days.map((d) => <div className="w-100 pa1 ba tc f7"><i className="fas fa-check" /></div>)}
            </div>







            <label className="mb3">Open Mic</label>
            <div className="">
              <i className="fab fa-youtube" /> YouTube
              <i className="fas fa-blog ml4" /> Blog
            </div>
            <label className="mb3 mt4">Reputation and Karma</label>
            <div className="">
              <div className="ba b--alt-yellow pa1 dib m">
                Level: 25 - Silver Wasteland Wanderer
              </div>
            </div>
            <div className="flex flex-wrap justify-around mt3 mr3-ns">
              <div className="mh1">8.3 - Player</div>
              <div className="mh1">4.2 - Seller</div>
              <div className="mh1">9.1 - Courier</div>
              <div className="mh1">7.5 - Buyer</div>
            </div>




        <div className="ph4-l pb4-l">
          <div className="mt4 mt0-ns w-50-ns">
            <label className="mb3">Characters</label>
            (builds and travel logs)
          </div>
        </div>
        <div className="ph4-l pb4-l">
          <div className="mt4 mt0-ns w-50-ns">
            <label className="mb3">Showcase</label>
          </div>
        </div>
        <div className="ph4-l pb4-l">
          <div className="mt4 mt0-ns w-50-ns">
            <label className="mb3">Trading</label>
            (looking for) (selling)
          </div>
        </div>
handles, looking-for, karma, showcase, market wants/trades
characters [ tabbed to show only 1 at time ]
 - character pics + buildout
 - character travelogs
promoted - blogger / content creator - verified badges

trader score
accounts
schedule
wants
guilds
playstyle
platforms & handles (all)
*/
export default Profile
