import paths from 'constants/paths'

export const bar = [
  // { to: paths.priceCheck, full: 'Trade', level: 1 },
  // { to: paths.trade, full: 'Trade', level: 1 },
  { to: paths.prices, full: 'Prices', level: 1 },
  { to: paths.social, full: 'Social', level: 1 },
  { to: paths.tools, full: 'Tools', level: 1 },
  // { to: paths.inv, full: 'Inventory', short: 'Inv', level: 1 },
  // { to: paths.dashboard, full: 'Dashboard', level: 2 },
  { remote: true, to: 'https://discord.gg/SXu8ehs', full: 'Discord', level: 2 },
  { to: paths.help, full: 'Help', level: 2 }
]

export const menu = [
  // {
  //   to: paths.admSummary,
  //   label: 'Admin',
  //   authz: 'admin_user'
  // },
  // {
  //   to: paths.story,
  //   label: 'Stories',
  //   icon: 'lightbulb',
  //   authz: 'admin_user'
  // },
  // {
  //   divider: true,
  //   id: 'signout-div'
  // },
  {
    to: paths.signout,
    label: 'Sign Out'
  }
]

const mod = { bar, menu }
export default mod
