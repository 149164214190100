import React, { useRef, useState, useContext } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Modal from 'tools/Modal'
import FileDialog from 'tools/Files/FileDialog'
import { uploadFile } from 'tools/Files/upload'
import GlobalContext from 'reducer/global'
import style from './index.module.scss'
import config from 'constants/config'
import { ICON } from 'constants/icons'

const defaultState = {
  image: '',
  allowZoomOut: false,
  position: { x: 0.5, y: 0.5 },
  scale: 1,
  rotate: 0,
  borderRadius: 0,
  preview: null,
  width: 200,
  height: 200,
  loaded: false
}

function Picture({
  image,
  refType,
  refId,
  subject,
  children,
  onSuccess,
  canEdit
}) {
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState('')
  // const canEditPic = !!refId
  return (
    <>
      <div className="dib">
        <div
          className={`relative ${canEdit ? 'pointer hover-hilite' : ''} br2`}
          onClick={() => canEdit && setEdit(!edit)}
        >
          {children}
          {canEdit && (
            <div className="absolute top-1 right-1">
              <i className={`${ICON.edit} gray`} />
            </div>
          )}
        </div>
      </div>
      <Modal width="fw-50" viewState={[edit, setEdit]} className="pa4">
        <Editor
          image={image}
          refType={refType}
          refId={refId}
          subject={subject}
          onSuccess={(v) => {
            setEdit(false)
            onSuccess(v)
          }}
          onFailure={(err) => {
            setError(err)
          }}
        />
        {error ? <div className="mt4 red">Cannot continue! {error}</div> : null}
      </Modal>
    </>
  )
}

function Editor({
  image,
  refType,
  refId,
  subject,
  onSuccess,
  onFailure = () => {}
}) {
  const [{ apollo }] = useContext(GlobalContext)
  const editor = useRef()
  const [state, setStateInner] = useState({ ...defaultState, image: image.url })
  const setState = (keypair) => setStateInner({ ...state, ...keypair })
  const setFromEvent = (ev, key) =>
    setState({ [key]: parseFloat(ev.target.value) })
  const mime = 'image/webp'

  const saveFile = () => {
    // @ts-ignore
    const canvas = editor.current.getImageScaledToCanvas().toDataURL(mime)

    fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => {
        // TODO: is there an easy way to get the canvas size?
        const size = 1
        uploadFile({
          apollo,
          subject,
          name: 'avatar',
          mime,
          refType,
          refId,
          size,
          src: blob,
          id: undefined,
          onSuccess,
          onFailure
        })
      })
  }

  // todo: add in uploading
  // https://www.npmjs.com/package/react-avatar-editor
  return (
    <div className="flex flex-column items-center">
      <div>
        <AvatarEditor
          ref={editor}
          scale={state.scale}
          width={state.width}
          height={state.height}
          position={state.position}
          onPositionChange={(position) => setState({ position })}
          rotate={state.rotate}
          image={state.image}
          className="editor-canvas"
        />
      </div>
      <div className="mt3 dib">
        <input
          type="range"
          step="0.01"
          min="0.5"
          max="2"
          name="scale"
          value={state.scale}
          disabled={!state.loaded}
          onChange={(ev) => setFromEvent(ev, 'scale')}
        />
      </div>
      <div className="flex justify-around mt3 w-100">
        <FileDialog
          idRef="upload-avatar"
          label="Choose File"
          onChange={(e) => setState({ image: e.target.files[0], loaded: true })}
          className="button border large"
        />
        <button className="large" onClick={saveFile} disabled={!state.loaded}>
          Save
        </button>
      </div>
    </div>
  )
}

// called as part of normalization
export function makeAvatarUrl(origin) {
  const avatar = origin?.avatar
  if (avatar?.path) {
    return { url: `${config.url.pub}${avatar.path}` }
  } else {
    return avatarUrl(origin)
  }
}

// called after normalization
export function avatarUrl(origin) {
  let url = origin?.avatar?.url
  if (url) {
    return { url }
  }
  let base = 'idea'
  if (origin.__typename === 'User') {
    base = 'avatar'
  }
  let loc = parseInt('0x' + (origin?.id || '').slice(28, 36)) % 16
  loc = loc ? loc + 1 : 1
  return { url: `${config.url.pub}default/${base}${loc}.gif`, default: true }
}

export function EditViewer({
  origin,
  subject,
  onSave,
  canEdit = true,
  round = true
}) {
  if (!origin._normal) {
    return null
  }
  return (
    <Picture
      image={origin.avatar}
      subject="avatar"
      onSuccess={onSave}
      refType={subject}
      refId={origin.id}
      canEdit={canEdit}
    >
      <AvatarView origin={origin} round={round} size="large" />
    </Picture>
  )
}

export function AvatarView({ origin, round, size }) {
  const { url } = avatarUrl(origin)
  return (
    <div
      className={`${style.image} ${style[size]} ${
        round ? style.round : ''
      } nomargin`}
    >
      <img src={url} alt="" />
    </div>
  )
}

export default EditViewer

export { style }
