export const selectStyles = {
  singleValue: (base) => ({
    ...base,
    color: 'var(--fo-text-white)',
    maxWidth: 'inherit'
    // const { maxWidth, position, transform, ...other } = base
    // console.log("MAX", maxWidth, position, transform)
    // other['color'] = 'var(--fo-text-white)'
    // return other
  }),
  control: (base) => ({
    ...base,
    outline: 'none',
    borderRadius: 0,
    paddingLeft: '.2rem',
    margin: '1px',
    backgroundColor: 'var(--fo-frame-overlay-lesser)',
    backdropFilter: 'blur(10px)',
    border: 'solid 2px transparent',
    minHeight: 'auto',
    padding: 0
  }),
  placeholder: (base) => ({
    ...base,
    fontStyle: 'italic',
    color: 'var(--fo-text-white)'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'rgba(255, 255, 255, 0.5)' : 'inherit',
    color: 'var(--fo-text-white)',
    padding: '.5rem .4rem .5rem .4rem'
  }),
  input: (base) => ({
    ...base,
    color: 'var(--fo-text-white)'
  }),
  dropdownIndicator: (base) => ({
    color: 'var(--fo-text-white)'
  }),
  indicatorSeparator: (base) => ({}),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    minHeight: 'auto'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    whiteSpace: 'nowrap',
    width: 'auto',
    left: 0,
    color: 'var(--fo-text-white)',
    backgroundColor: '#111',
    backdropFilter: 'blur(10px)',
    padding: 0
  })
}
export function selectStyle(user) {
  return {
    option: () => ({}),
    control: () => ({})
  }
}
