// @ts-nocheck
import React, { useContext } from 'react'
import { NavLink as InnerNavLink, useLocation } from 'react-router-dom'
import style from './index.module.scss'
import { MenuList, MenuItem, MenuButton } from 'react-menu-list'
import log from 'utils/log'
import GlobalContext from 'reducer/global'
import menus from './config'
import paths from 'constants/paths'

function logNav(target) {
  log.event({ category: 'nav', action: '/' + target.toLowerCase() })
}

export function NavLink(props) {
  const { label, children, className, ...other } = props
  return (
    <InnerNavLink
      activeClassName={style.active}
      className={`${className} ${style.navlink} pa1`}
      onClick={() => {
        logNav(label)
      }}
      {...other}
    >
      {label || children}
    </InnerNavLink>
  )
}

// react-menu-list
function MenuLink(props) {
  const { className, ...other } = props
  return (
    <MenuItem>
      <NavLink
        className={`${className} w-100 tl`}
        {...other}
        onClick={() => {
          logNav(props.label)
        }}
      />
    </MenuItem>
  )
}

// todo:
// + add icons (gigstartup.xyz has them)
// + responsive collapse menus
export function NavBar(props) {
  const location = useLocation()

  if (location.pathname === '/') {
    return null
  }
  return (
    <div
      className={`${style.navbar} navbar f7 ma0 pa1 flex justify-between items-center`}
    >
      <InnerNavLink
        to="/"
        className={style.navlink}
        onClick={() => {
          logNav('')
        }}
      >
        <span className="mr-dafoe fo-text-white ttn f5">RogueTrader</span>
      </InnerNavLink>
      {menus.bar.map((elem) => {
        if (elem.level !== 1) {
          return null
        }
        const label = elem.short || elem.full
        return <NavLink key={label} to={elem.to} label={label} />
      })}
      <ProfileMenu />
    </div>
  )
}

export function ProfileMenu({ className = '', handle = true }) {
  const [state] = useContext(GlobalContext)

  const isAuthAdmin = state.user.can('auth_admin')
  const isSummaryAdmin = state.user.can('summary_admin')
  const unread = state.user.unreadThreads || []
  // <MenuLink
  //   to="/messages/"
  //   label="messages"
  //   className={`${style.menuitem} pa2 `}
  // />
  //
  return state.user.isIdentified ? (
    <MenuButton
      className={`${style.navlink} pa1 ${className}`}
      positionOptions={{
        position: 'bottom',
        vAlign: 'top',
        hAlign: 'center'
      }}
      menu={
        <div className={`${style.navbar} f7 ba b--transparent `}>
          <MenuList>
            <MenuLink
              to={paths.preferences}
              label="Preferences"
              className={`${style.menuitem} pa2 `}
            />
            <MenuLink
              to="/help/"
              label="help"
              className={`${style.menuitem} pa2 `}
            />
            <MenuLink
              to="/support"
              label="support"
              className={`${style.menuitem} pa2 `}
            />
            <MenuLink
              to="/signout"
              label="signout"
              className={`${style.menuitem} pa2 `}
            />
            {isAuthAdmin || isSummaryAdmin ? (
              <div className="bt b--gray mv1">
                {isAuthAdmin ? (
                  <MenuLink
                    to="/adm/users"
                    label="user admin"
                    className={`${style.menuitem} pa2`}
                  />
                ) : null}
                {isSummaryAdmin ? (
                  <MenuLink
                    to="/adm/summary"
                    label="summary admin"
                    className={`${style.menuitem} pa2`}
                  />
                ) : null}
              </div>
            ) : null}
          </MenuList>
        </div>
      }
    >
      <div className={`flex items-start w-100 justify-center`}>
        {unread.length > 0 ? (
          <i className="fas fa-circle red" style={{ fontSize: '6px' }} />
        ) : null}
        {handle ? state.user.handle || 'me' : null}
        <i className="pl2 fas fa-bars" />
      </div>
    </MenuButton>
  ) : (
    <NavLink to="/signon/" label="signon" className={className} />
  )
}
// <MenuLink
//   to="/local-import"
//   label="CSV Import"
//   className={`${style.menuitem} pa2 `}
// />

export default NavBar
