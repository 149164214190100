export const verbs = [
  'attenuating',
  'adjusting',
  'loading',
  'calibrating',
  'rerouting',
  'detecting',
  'resequencing',
  'supercharging'
]
export const adverbs = ['port', 'central', 'dorsal', 'actuator', 'ventral']

export const adjectives = [
  'transitory',
  'parabolic',
  'atomic',
  'radium',
  'tactical',
  'thoron',
  'verteron',
  'dermatiraelian',
  'lexorian',
  'ablative',
  'polarity',
  'plasma'
]

export const nouns = [
  'decompilers',
  'plate',
  'plasma fluid',
  'distribution',
  'muon core',
  'detectors',
  'magnetic wave',
  'sensor',
  'resistance',
  'laser',
  'containment field',
  'thrust',
  'rocket',
  'plume',
  'vacuum',
  'isotope',
  'conduit',
  'gamma ray',
  'dynoscanner'
]

export const sub_nouns = [
  'pattern',
  'polarity',
  'detector',
  'buffer',
  'matrix',
  'sequencer',
  'assembly',
  'housing',
  'chamber',
  'network',
  'bank',
  'thruster'
]
