import gql from 'graphql-tag'

export const publicUserListVars = `
  id
  handle
  name
  avatar
  verified
  lastSeen
  platforms {
    platform
    handles
  }
  playstyles
`

export const publicUserValues = `
  ${publicUserListVars}
  tags {
    id
    type
    public
    tag {
      id
      label
      meta
    }
  }
  data { id, type, value }
  updatedAt
  insertedAt
`

const userValues = `
  ${publicUserValues}
  emails {id,address,primary,verified}
  phones {id,number,primary,verified}
  settings
  access { roles, actions }
  authStatus
  unreadThreads { id }
  data { id, type, value}
`

export const LOAD_USER = gql`
query user($id: String!) {
  users(id: $id) {
    success
    reason
    result {
      ${userValues}
    }
  }
}
`

export const SET_NOTIFY_PREF = gql`
  mutation upsertNotifyUserPref($pref: InputNotifyUserPref!, $id: String!) {
    upsertNotifyUserPref(pref: $pref, id: $id) {
      success
      reason
      result {
        id
        type
        pref
        allow
      }
    }
  }
`

export const NOTIFY_PREFS = gql`
  query notifyPrefs($id: String!) {
    users(id: $id) {
      success
      reason
      result {
        id
        notifyPrefs {
          id
          type
          pref
          allow
          updatedAt
        }
      }
    }
  }
`

export const LIST_PUBLIC_PERSON = gql`
  query publicUser($target: String, $id: String) {
    publicUser(target: $target, id: $id) {
      success
      reason
      result {
        ${publicUserListVars}
      }
    }
  }
`

export const PUBLIC_PERSON = gql`
  query publicUser($target: String!) {
    publicUser(target: $target) {
      success
      reason
      result {
        ${publicUserValues}
      }
    }
  }
`

export const LOAD_PUBLIC_PERSON = gql`
  query publicUser($id: String) {
    publicUser(id: $id) {
      success
      reason
      result {
        ${publicUserValues}
        projects {
          id
          type
          project {
            id
            shortId
            title
            status
            subtitle
            updatedAt
            insertedAt
            avatar
          }
        }
        journeys {
          id
        }
      }
    }
  }
`

export const UPDATE_PERSON = gql`
mutation updateUser(
    $id: String, $action: ChangeActions,
    $user: InputUser, $handle: InputHandle, $email: InputEmail,
    $phone: InputPhone, $data: InputUserData, $role: InputRole)
  {
    updateUser(
      id: $id, action: $action,
      user: $user, handle: $handle, email: $email,
      phone: $phone, data: $data, role: $role)
    {
      success
      reason
      result {
        ${userValues}
      }
    }
  }
`

export const LIST_PUBLIC_PEOPLE = gql`
query publicPeople($filter: PeopleFilter!) {
  publicPeople(filter: $filter) {
    success
    reason
    total
    result {
      ${publicUserValues}
    }
  }
}
`

export const LIST_PEOPLE = gql`
query people($filter: PeopleFilter!) {
  people(filter: $filter) {
    success
    reason
    total
    results {
      ${userValues}
      last_seen
    }
  }
}
`

export const GEN_APIKEY = gql`
  mutation genApiKey {
    genApiKey {
      access
      validation
    }
  }
`

export const GET_INVITES = gql`
  mutation getInvites {
    getInvites {
      codes
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($current: String, $new: String!, $code: String) {
    changePassword(current: $current, new: $new, code: $code) {
      success
      reason
    }
  }
`

export const MY_FACTORS = gql`
  query myFactors($type: String) {
    self {
      id
      factors(type: $type) {
        id
        type
        expiresAt
      }
    }
  }
`

export const GET_ACCESS = gql`
  query getAccess($type: AccessDomain!, $refId: String!) {
    getAccess(type: $type, refId: $refId)
  }
`

export const inviteValues = `
  id
  shortId
  expiresAt
  requestorId
  requestType
  targetType
  requestAccess
  resourceType
  resourceId
  projectId
  orgId
  email
  targetId
  note
  target {
    ${publicUserValues}
    id
    name
    handle
  }
`

export const INVITE = gql`
  mutation invite(
    $action: InviteAction!
    $targetType: InviteTargetType
    $requestType: InviteRequestType
    $requestAccess: InviteAccessType
    $responseType: InviteResponseType
    $responseAccess: InviteAccessType
    $resourceType: InviteResourceType
    $resourceId: String
    $projectId: String
    $targetId: String
    $orgId: String
    $note: String
    $email: String
    $shortId: String
  ) {
    invite(
      action: $action
      targetType: $targetType
      requestType: $requestType
      requestAccess: $requestAccess
      responseType: $responseType
      responseAccess: $responseAccess
      resourceType: $resourceType
      resourceId: $resourceId
      targetId: $targetId
      projectId: $projectId
      orgId: $orgId
      note: $note
      email: $email
      shortId: $shortId
    ) {
      success
      reason
      result
    }
  }
`

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($code: String) {
    deleteAccount(code: $code) {
      success
      reason
    }
  }
`
