import React from 'react'
import Modal from 'react-modal'
import Scrollbar from 'tools/Scrollbar'
import style from './index.module.scss'

function MyModal({
  width,
  viewState: [modalOpen, setModalOpen],
  header = undefined,
  children = undefined,
  noScroll = false,
  className = '',
  noPad = undefined
}) {
  if (width === 'fw-min' || width === 'fw-shrink') {
    noScroll = true
  }

  // TODO: in the future address ariaHideApp
  // warn msg:
  //    Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.

  if (modalOpen) {
    return (
      <Modal
        isOpen={!!modalOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalOpen(false)}
        className={style.modal}
        overlayClassName={style.overlay}
      >
        <div
          className={`${style.frame} ${width ? style[width] : style['fw-50']}
          theme-frame theme-bg-flat ${style.modal}`}
        >
          {header ? (
            <div
              className={`theme-bg-accent b ph2 pv2 pa1 w-100`}
              // style={{
              //   borderTopLeftRadius: '0.5rem',
              //   borderTopRightRadius: '0.5rem'
              // }}
            >
              {header}
            </div>
          ) : null}
          <div
            className={`${style.close} pa1 flex-center br2 hover-hilite`}
            onClick={() => setModalOpen(false)}
          >
            <i className="fas fa-times b" />
          </div>
          {noScroll ? (
            <div className={`${className ? className : style.body} w-100`}>
              {children}
            </div>
          ) : (
            <Scrollbar className="scroll2h">
              <div className={`${className ? className : style.body} w-100`}>
                {children}
              </div>
            </Scrollbar>
          )}
        </div>
      </Modal>
    )
  } else {
    return null
  }
}

export default MyModal
