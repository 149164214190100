import React, { useState, useEffect } from 'react'
import moment from 'moment'
import style from './index.module.scss'

////////////////////////////////////////////////////////////////////////////////
function NukeCodes({ results, className = '' }) {
  // const [, nDispatch] = useContext(NotifyStore)
  // const [context] = useContext(GlobalContext)
  const range = results && results.nukecodes && results.nukecodes.range
  const [time, setTime] = useState(range ? range : [false, <></>])

  useEffect(() => {
    let isMounted = true
    if (range) {
      setTimeout(() => {
        if (isMounted) {
          setTime(countTime(range))
        }
      }, 1000)
    }
    return () => {
      isMounted = false
    }
  }, [time, range])

  if (!results || !results.nukecodes) return null
  return (
    <div className={`${className} flex-ns items-center justify-center mb3`}>
      <div className="mh3-ns mb3 mb0-ns fo-frame pa2">
        <div className="ttu tc fo-alt-yellow">NUKE CODES {time[1]}</div>
        <div className="i mt2 f7 tc">
          Click/Tap the silo to report you have used the code and it worked!
        </div>
      </div>
      <div className="flex justify-center flex-wrap flex-nowrap-ns">
        {results.nukecodes.codes.map((silo) => {
          const sname = Object.keys(silo)[0]
          // const launches = results.launches[key] || 0
          let code = silo[sname]
          code = code.slice(0, 3) + ' ' + code.slice(3, 5) + ' ' + code.slice(5)
          return (
            <div key={sname} className="mh1 tc  mb3 mb0-ns">
              <button className={`ph3 tc ${style.button} box-shadow`} disabled>
                <div className="mr2 ttu mb2 tc w-100">{sname}</div>
                <div className={time[0] ? 'fo-text-white b' : 'strike gray'}>
                  {code}
                </div>
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function countTime([starting, ending], prefix) {
  const f = (x) => Math.floor(x)
  const pad = (x) => (x < 10 ? `0${f(x)}` : `${f(x)}`)
  const now = new Date().getTime() / 1000
  let then = moment(ending).unix()
  const start = moment(starting).unix()

  if (then - now < 0) {
    return [false, <span className="red mh1">ARE EXPIRED</span>, start, then]
  }

  let valid = true
  let color = ''
  let label = 'EXPIRE IN '

  if (start > now) {
    label = 'BEGIN IN'
    then = start
    color = 'red'
    valid = false
  }

  const duration = moment.duration(then - now, 'seconds')
  const days = duration.days()
  let parts = ''
  if (days > 1) {
    parts += `${days} days `
  } else if (days > 0) {
    parts += `${days} day `
  }

  parts += pad(duration.hours()) + ':'
  parts += pad(duration.minutes()) + ':'
  return [
    valid,
    <span className={color}>
      {label} {parts + pad(duration.seconds())}
    </span>,
    start,
    then
  ]
}

export default NukeCodes
