import React, { useContext, useCallback } from 'react'
import { REQUEST_PASSWORD_RESET } from '../graphql'
import { useMutation } from '@apollo/client'
import { UserContext, R_USER } from '../reducer'
import { useReady, passwordStyle, passwordChange } from './lib'

function ResetCode({ hasPassword = false, isAuthed }) {
  const [user, dispatch] = useContext(UserContext)
  const form = user.passform
  const isReady = useReady(isAuthed)
  const updateForm = useCallback(
    (merge) => dispatch({ type: R_USER.CHANGE_PASSFORM, merge: merge }),
    [dispatch]
  )

  const [requestReset] = useMutation(REQUEST_PASSWORD_RESET)

  const requestCode = useCallback(() => {
    requestReset({
      variables: { email: user.passform.email || user.primaryEmail.address },
      update(cache, result) {
        updateForm({
          codeMsg: (
            <div className="fo-text-white i">
              Your request is submitted. Check your email for further
              instructions.
            </div>
          )
        })
      }
    })
  }, [user, requestReset, updateForm])

  return (
    <div
      onBlur={(ev) => {
        ev.stopPropagation()
        updateForm({ showCode: false })
      }}
    >
      <div className="mt3">
        <div className="b f6">
          {isAuthed ? 'Current Password' : 'Reset Code'}
          <i
            className={`fas fa-${
              form.showCode ? 'eye-slash' : 'eye'
            } ml2 hover pointer`}
            onClick={() => updateForm({ showCode: !form.showCode })}
          />
        </div>
      </div>
      <div className="mt2 flex-items">
        <input
          style={passwordStyle}
          type={form.showCode ? 'text' : 'password'}
          value={form.code}
          placeholder="Current Password or Reset Code"
          onChange={(ev) => passwordChange(dispatch, ev, 'code', form, isReady)}
        />
        {isAuthed && user.primaryEmail ? (
          <button
            className="button plain nowrap medium f5 ml3"
            onClick={requestCode}
          >
            Request Reset Code
          </button>
        ) : null}
      </div>
      {form.codeMsg ? <div className="mt3">{form.codeMsg}</div> : null}
    </div>
  )
}

export default ResetCode
