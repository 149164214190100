import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import style from './index.module.scss'
import {
  NavTabsProvider,
  NavTabsContext,
  normalizeTabs,
  makeTab,
  R_TABS
} from './reducer'
import Activity from './Activity'
import { Redirect } from 'tools/Page'

////////////////////////////////////////////////////////////////////////////////
const defaultProps = {}
function useTabs({ tabs, path, param, props = defaultProps }) {
  const [, dispatch] = useContext(NavTabsContext)
  const params = useParams()

  useEffect(() => {
    dispatch({ type: R_TABS.SET, value: { tabs, props, param, params, path } })
  }, [tabs, dispatch, props, param, params, path])
}

////////////////////////////////////////////////////////////////////////////////
function activeTabs(origin, active, inactive) {
  let activity = active.reduce(
    (acc, tab) => {
      acc[tab] = true
      return acc
    },
    { ...(origin.tabProps.activity ?? {}) }
  )
  activity = inactive.reduce((acc, tab) => {
    delete acc[tab]
    return acc
  }, activity)
  origin.tabProps = { ...origin.tabProps, activity }
  return origin
}

function addTab(orig, tab) {
  return closeTab(orig, tab.id).concat(makeTab(tab))
}

function removeTab(orig, tabId) {
  return orig.filter((t) => t.id !== tabId)
}

function openTab(orig, tab, meta = {}) {
  return orig.map((t) => {
    if (t.id === tab.id) {
      return { ...t, view: () => ({}), ...tab, ...meta }
    } else {
      return t
    }
  })
}

function closeTab(orig, tabId) {
  return orig.map((t) => {
    if (t.id === tabId) {
      return { ...t, view: () => false }
    } else {
      return t
    }
  })
}

export function Navigator({ config, children = undefined }) {
  return (
    <NavTabsProvider config={config}>{children || <NavTabs />}</NavTabsProvider>
  )
}

////////////////////////////////////////////////////////////////////////////////
function NavTabs({
  className = 'mh1-ns mh3-m mh4-l mt3-ns mt4-l',
  controls = undefined,
  raw = false,
  children = undefined
}) {
  const [{ ready, tabs, order, param, props, path: pathFunc, root }] = useContext(
    NavTabsContext
  )
  let path = pathFunc
  if (!pathFunc) {
    path = ({ tabId }) => `${root}/${tabId}`
  }
  const history = useHistory()
  const params = useParams()
  if (!ready) {
    return null
  }
  let tabId = params[param]
  let current = tabs[tabId]
  if (!current) {
    tabId = order.find((id) => tabs[id].orig)
    current = tabs[tabId]
    if (!current) {
      current = tabs[order[0]]
    }
    return <Redirect path={path({ tabId: current.id })} />
  }

  const { Component: Tab, hideTabs } = current
  if (hideTabs) {
    return <Tab tab={tabId} tabId={tabId} {...props} />
  }
  return (
    <>
      <div className={`${className} tabs`}>
        <div className="flex justify-between">
          {order.map((id) => {
            const tab = tabs[id]
            const args = { tabId: tab.id, tab, params, tabs, props, current }
            if (!tab.view(args)) return null
            return (
              <div
                key={id}
                className={`tab ${
                  tabId === id ? 'active' : ''
                } w-100 pa1 pa2-m pa2-l f7 f6-l f6-m`}
                onClick={(ev) => {
                  ev.preventDefault()
                  ev.stopPropagation()
                  if (tab.path) {
                    history.push(tab.path(args))
                  } else {
                    history.push(path(args))
                  }
                }}
              >
                {tab.name(args)}
                <Activity show={tab.activity} />
                {tab.close ? (
                  <div className={style.close}>
                    <div
                      className={'button clear pv1 ph2'}
                      onClick={(ev) => {
                        ev.stopPropagation()
                        ev.preventDefault()
                        tab.close(id)
                      }}
                    >
                      <i className="fas fa-times" />
                    </div>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
        {controls ? <div className={style.controls}>{controls}</div> : null}
      </div>
      <div className={raw ? '' : 'fo-frame mh1 mh3-m mh4-l mb5'}>
        {children}
        <Tab tab={tabId} tabId={tabId} {...props} {...current.props} />
      </div>
    </>
  )
}

export default NavTabs
export {
  NavTabsProvider,
  NavTabsContext,
  NavTabs,
  useTabs,
  makeTab,
  openTab,
  closeTab,
  addTab,
  removeTab,
  activeTabs,
  normalizeTabs,
  R_TABS
}
