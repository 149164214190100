import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ExtLink } from 'tools/Links'
import { useQuery } from '@apollo/client'
import Scrollbar from 'tools/Scrollbar'
import { DASHBOARD } from 'constants/Dashboard'
import { ProfileMenu } from 'components/Root/NavBar'
import NukeCodes from '../NukeCodes'
import style from './index.module.scss'
import menus from 'components/Root/NavBar/config'

const D_STATE = {}

export function Dashboard(props) {
  const [results, setResults] = useState(D_STATE)

  useQuery(DASHBOARD, {
    fetchPolicy: 'cache-and-network',
    onCompleted({ dashboard: { results: r } }) {
      if (r.items) {
        const totals = {
          options: Object.keys(r.items.options).reduce(
            (acc, plat) => acc + r.items.options[plat],
            0
          ),
          platforms: Object.keys(r.items.platforms).reduce(
            (acc, plat) => acc + r.items.platforms[plat],
            0
          ),
          users_active: Object.keys(r.users_active).reduce(
            (acc, plat) => acc + r.users_active[plat],
            0
          ),
          users_today: Object.keys(r.users_today).reduce(
            (acc, plat) => acc + r.users_today[plat],
            0
          )
        }
        setResults({ ...r, totals })
      }
    }
  })

  return (
    <Scrollbar className={`scroll ${style.scroll}`}>
      <div className={style.grid}>
        <HomeBrand className={style.brand} />
        <HomeMenu className={style.menus} />
        <Announcements className={style.announce} />
        <div className={style.codes}>
          <NukeCodes results={results} />
          <NotBeth />
        </div>
      </div>
    </Scrollbar>
  )
}

function NotBeth() {
  return (
    <div className="tc f6 b i w-100 mb4 mb2-ns mt4 mt0-ns">
      RogueTrader is provided by{' '}
      <ExtLink to="https://revenant.studio/">Revenant Creative Studio</ExtLink>
    </div>
  )
}

// <span className="fo-alt-yellow">WELCOME!</span>
// <ul>
//   <li>
//     <a href="https://enrequiem.com/" style={{ color: '#08e' }}>
//       <img
//         src="https://enrequiem.com/assets/img/enreq-logo.svg"
//         style={{ height: '0.85rem' }}
//         alt="En Requiem"
//         className=""
//       />
//     </a>{' '}
//     — Cyberpunk in space RPG? Checkout another project we are working on.
//   </li>
//   <li>
//     <b className="red">Atom Games!</b>&nbsp;This is a{' '}
//     <a href="https://www.reddit.com/r/fo76/comments/lawmds/new_gameplay_ideas_pvp_hats_game_balls_and_goals/">
//       New gameplay idea
//     </a>{' '}
//     we are helping promote. Read about it on Reddit - if you like the idea
//     please upvote!
//   </li>
// </ul>
function Announcements({ className }) {
  return (
    <div className={className}>
      <div
        className={`${style.announceBox} box-shadow fo-frame pa3 mt4 mt0-ns mr4-ns mt4-ns`}
      >
        <div className="mb3 f4 b">Want more Fallout?</div>

        <a
          href="https://RidersOfTheStars.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://i.revenant.studio/15/7a19ef27a76846a2966d97e22932122d"
            className="fl mr3"
            style={{ height: '8rem' }}
            alt="Atom Bomb Baby"
          />
          A fallout inspired Retro-Future novel!
          <div className="mv3 f3">
            <b>
              An <i>"action-packed apocalyptic SF yarn"</i>
            </b>{' '}
            — Kirkus Reviews
          </div>
        </a>

        <div style={{ clear: 'both' }} />
        <div className="mt3 f6" style={{ clear: 'both' }}>
          If you know somebody interested, let them know! You can download a free
          ePub preview from the link above.
        </div>
      </div>
    </div>
  )
}

function Backatcha({ className }) {
  return (
    <div className={className}>
      <img
        src="/assets/img/vb-350.png"
        alt="Back-atcha"
        className="w-50 w-75-m w-100-l"
        style={{ width: '150px' }}
      />
    </div>
  )
}

function HomeMenu({ className }) {
  const smStyle = `${style.opt} ${style.optSm}`
  return (
    <div className={`${className} mt3 mt0-ns pb6-ns roboto-black b text-outline`}>
      <div className="flex flex-column flex-row-ns">
        <Backatcha className="dn db-ns ml-auto mr-auto" />
        <div
          className="ml-auto mr-auto ml4-ns ttu tl"
          style={{ width: 'fit-content' }}
        >
          <div className="f2">
            {menus.bar.map((e, x) => {
              if (e.level !== 1) {
                return null
              }
              return (
                <Link to={e.to} className={style.opt} key={x}>
                  {e.full}
                </Link>
              )
            })}
          </div>
          <div className="f5 mt1">
            {menus.bar.map((e, x) => {
              if (e.level !== 2) {
                return null
              }
              if (e.remote) {
                return (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={e.to}
                    className={smStyle}
                    key={x}
                  >
                    {e.full}
                  </a>
                )
              } else {
                return (
                  <Link to={e.to} className={smStyle} key={x}>
                    {e.full}
                  </Link>
                )
              }
            })}
            <ProfileMenu
              className={`${style.profileMenu} text-outline ${style.opt} ${style.optSm} `}
              handle={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function HomeBrand({ className }) {
  return (
    <div className={`${className} flex`}>
      <Backatcha className="dn-ns" />
      <div className="ml5-ns mt3 fotext-white text-outline mr3">
        <div className="mb3 mr-dafoe fotext-white f1 tc">RogueTrader</div>
        <div className="b i mt1 f7 mb0-ns text-outline tc">
          Your Independent Fallout 76 Companion App
        </div>
      </div>
    </div>
  )
}

export default Dashboard
