import gql from 'graphql-tag'

export const CHARACTERS = gql`
  query characters($systemId: String!, $ownerId: String!) {
    characters(systemId: $systemId, ownerId: $ownerId) {
      id
      platform
      name
      meta
    }
  }
`

export const UPSERT_CHARACTER = gql`
  mutation upsertCharacter(
    $systemId: String
    $platform: String
    $name: String!
    $meta: String
    $id: String
  ) {
    upsertCharacter(
      systemId: $systemId
      platform: $platform
      name: $name
      meta: $meta
      id: $id
    ) {
      id
      platform
      name
      meta
    }
  }
`

export const DELETE_CHARACTER = gql`
  mutation deleteCharacter($id: String!) {
    deleteCharacter(id: $id) {
      success
      reason
    }
  }
`

export const PLATFORM_ICONS = {
  ps4: 'playstation',
  xbox: 'xbox',
  pc: 'windows'
}
