import React, { useState, useContext } from 'react'
import { MY_FACTORS } from '../graphql'
import { useQuery } from '@apollo/client'
import GlobalContext from 'reducer/global'
import NewPassword from './NewPassword'
import ResetCode from './ResetCode'
import { defaultFactors } from './lib'

////////////////////////////////////////////////////////////////////////////////
export function ChangePassword() {
  const [factors, setFactors] = useState(defaultFactors)
  const [{ user }] = useContext(GlobalContext)

  const isAuthed = user.isAuthed
  useQuery(MY_FACTORS, {
    skip: !isAuthed,
    onCompleted(data) {
      setFactors(
        (data?.self?.factors || []).reduce((acc, factor) => {
          acc[factor.type] = true
          return acc
        }, {})
      )
    }
  })
  const auths = user.settings.authAllowed || {}

  return (
    <div className="mb4">
      <label className="f5 b db mb3 heading">Change Password</label>
      {isAuthed ? (
        <>
          {!factors.password ? (
            <div className="">
              You are signed in with
              <b>
                {auths.google
                  ? ' Google Authentication '
                  : ' Federated Authentication '}
              </b>
              which doesn't use a password.
              <div className="mb2" />
              In order to set a password, you need to request a Password Reset
              Code.
            </div>
          ) : null}
        </>
      ) : (
        <>{/*
        <EmailInput value={[email, setEmail]} />
        */}</>
      )}
      <ResetCode hasPassword={!!factors.password} isAuthed={isAuthed} />
      <NewPassword isAuthed={isAuthed} />
    </div>
  )
}

export default ChangePassword
