import React, { useState, useEffect } from 'react'
import { NOTIFY_PREFS, SET_NOTIFY_PREF } from '../graphql'
import Toggle from 'tools/Toggle'
import { useMutation, useQuery } from '@apollo/client'
import config from 'constants/config'
import { intcmp } from 'utils/string'

const PREFS = {
  events: {
    change: true,
    label: 'Upcoming Events',
    name: (
      <>
        Upcoming{' '}
        <i>
          <b>{config.name}</b>
        </i>{' '}
        events near you.
      </>
    ),
    order: 2
  },
  releases: {
    change: true,
    label: 'Book Releases',
    name: (
      <>
        Information about{' '}
        <i>
          <b>{config.name}</b>
        </i>{' '}
        book releases and progress.
      </>
    ),
    order: 1
  },
  alpha: {
    change: true,
    label: 'Alpha Reader',
    name: <>I'd like to sign up to be an Alpha Reader</>,
    order: 4
  },
  beta: {
    change: true,
    label: 'Beta Reader',
    name: <>I'd like to sign up to be a Beta Reader</>,
    order: 5
  },
  security: {
    change: false,
    label: 'Security (Required)',
    name: 'Account Security',
    order: 98
  },
  billing: {
    change: false,
    hidden: true,
    label: 'Billing (Required)',
    name: 'Account Billing',
    order: 99
  },
  system_updates: {
    change: false,
    label: 'System Updates (Required)',
    name: 'System Changes as it affects your account',
    order: 100
  }
}
Object.keys(PREFS).forEach((n) => {
  PREFS[n].id = `user-${n}`
  PREFS[n].pref = n
  PREFS[n].type = 'user'
})

const DEF_PREFS = {
  order: Object.keys(PREFS).sort((a, b) =>
    intcmp(PREFS[a].order, PREFS[b].order)
  ),
  prefs: PREFS
}

function EmailPrefs() {
  const [prefs, setPrefs] = useState(undefined)
  const [val, setVal] = useState(undefined)
  const { data, refetch } = useQuery(NOTIFY_PREFS, {
    fetchPolicy: 'network-only'
  })

  // I prefer onCompleted() but it's buggy
  useEffect(() => {
    if (data) {
      const prefs = data.self.notifyPrefs.reduce((accum, pref) => {
        accum[pref.pref] = pref
        return accum
      }, {})
      const order = [...DEF_PREFS.order]
      const leftover = { ...prefs }
      order.forEach((key) => delete leftover[key])
      order.concat(Object.keys(leftover))
      setPrefs({ prefs, order })
    }
  }, [data, setPrefs])

  return (
    <>
      <label className="f5 b db mb3 heading">Commitment</label>
      <div className="mb4">
        <div className="mb3" />
        We respect your inbox, and do not want to send you any more messages than
        you desire. We do not ever sell send your personal information to anybody
        else, and we commit to do our best to protect it.
      </div>

      <label className="f5 b db mb3 heading">Subscriptions</label>
      <div className="mb4">
        Let us know what you would like to receive messages about:
      </div>

      {prefs && (
        <table className="list">
          <tbody>
            {prefs.order.map((name) => {
              const pref = prefs.prefs[name]
              return (
                <Pref
                  name={name}
                  pref={pref}
                  onChange={({ success, reason, result }) => {
                    if (success) {
                      if (result.allow !== pref.allow) {
                        const label = PREFS[result.pref].label || result.pref
                        refetch()
                        setVal(
                          <div className="green">
                            <i className="fas fa-check mr2" />
                            Saved {label} preference
                          </div>
                        )
                      }
                    } else {
                      setVal(<div className="red">{reason}</div>)
                    }
                  }}
                  key={pref.id}
                />
              )
            })}
          </tbody>
        </table>
      )}
      {val && <div className="f6 i ph2 pv3">{val}</div>}
    </>
  )
}

function getSpec(label) {
  if (PREFS[label]) {
    return PREFS[label]
  } else {
    return {
      label,
      change: true
    }
  }
}

function Pref({ pref, name, onChange }) {
  const spec = getSpec(pref.pref)
  const [mutator] = useMutation(SET_NOTIFY_PREF)
  if (spec.hidden) {
    return null
  }
  return (
    <tr
      className={spec.change ? 'hover-hilite pointer' : ''}
      onClick={() => {
        if (spec.change) {
          mutator({
            variables: {
              pref: { pref: pref.pref, type: pref.type, allow: !pref.allow }
            },
            update(cache, { data: { upsertNotifyUserPref } }) {
              onChange(upsertNotifyUserPref)
            }
          })
        }
      }}
    >
      <td>
        <Toggle value={pref.allow} disabled={!spec.change} />
      </td>
      <td className="b">{spec.label}</td>
      <td>{spec.name}</td>
    </tr>
  )
}

export default EmailPrefs
