import React, { useContext, useState } from 'react'
import { DELETE_ACCOUNT } from '../graphql'
import { useMutation } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import IdentifiedUser from 'tools/IdentifiedUser'
import UserContext from '../reducer'

////////////////////////////////////////////////////////////////////////////////
function DeleteAccount() {
  const [show, setShow] = useState(false)
  return (
    <div className="mt5 ba b--red br2 pa4">
      <label className="f4 b db heading">Danger Zone!</label>
      <div className="flex items-center mt4 hover-hilite pa2 br2">
        {show ? (
          <DeleteAccountModal />
        ) : (
          <>
            <div className="mr-auto">
              <div className="b db">Delete My Account</div>
              <div>
                Once you delete your account there is no going back. Deleting your
                account here will delete it on revenant.studio as well. Be
                certain!
              </div>
            </div>
            <button
              className="ml2 error-bg medium nowrap"
              onClick={() => setShow(true)}
            >
              Delete Account
            </button>
          </>
        )}
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function DeleteAccountModal() {
  const [done, setDone] = useState(false)
  const [deleteAccount] = useMutation(DELETE_ACCOUNT)
  return (
    <div>
      <div className="flex items-center">
        <div className="mr-auto">
          Please be certain this is what you would like to do. If so, you will
          receive a confirmation email with a link. Clicking on that link will
          permanently delete your account on both roguetrader and revenant.studio.
          There is no way of recovering!
        </div>
        <button
          className="ml2 error-bg nowrap medium"
          onClick={() => {
            deleteAccount({
              update(cache, data) {
                setDone(true)
              }
            })
          }}
        >
          Delete Account!
        </button>
      </div>
      {done && (
        <div className="mt3 red i">
          Your request is submitted. Please check your email.
        </div>
      )}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
export function DeleteAccountConfirm() {
  const [user] = useContext(UserContext)
  const [msg, setMsg] = useState(undefined)
  const [deleteAccount] = useMutation(DELETE_ACCOUNT)
  const { code } = useParams()
  const history = useHistory()

  const deleteNow = () => {
    deleteAccount({
      variables: { code },
      update(
        cache,
        {
          data: {
            deleteAccount: { success, reason }
          }
        }
      ) {
        if (success) {
          history.replace('/signout')
        } else {
          setMsg(
            'Unable to delete account. Has the code expired? Is it for the account you are signed in with?'
          )
        }
      }
    })
  }
  return (
    <IdentifiedUser>
      <div className="fo-frame max-view-page mt4 mb5 pa4">
        <div className="heading f3 mb3 ">Delete Account</div>
        {user.name} ({user.handle}) we are sorry to see you go!
        <p />
        Not to be pedantic, but we want to make certain you would like to
        permanently delete your account, with no ability for recovery.
        <div className="mt4 flex-center">
          <button className="error-bg medium" onClick={deleteNow}>
            Permanently Delete Account
          </button>
        </div>
        {msg && <div className="mt4 red flex-center i">{msg}</div>}
      </div>
    </IdentifiedUser>
  )
}

export default DeleteAccount
