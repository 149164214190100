import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Modal from 'tools/Modal'
import CopyClipboard from 'tools/CopyClipboard'
import { PLATFORM_ICONS, UPSERT_CHARACTER } from '../../../../constants/Character'
import style from './index.module.scss'

function CharacterDetail({ char, player, deleteChar, refetch, isMine = false }) {
  const [expand, setExpand] = useState(true)
  const [hover, setHover] = useState(false)
  const [name, setName] = useState(char.name)
  const [dirty, setDirty] = useState(0)
  const [upsertChar] = useMutation(UPSERT_CHARACTER)

  return (
    <div>
      <div
        className="flex items-center mv2  pa1 justify-between"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="w-100 flex items-center"
          onClick={() => {
            setExpand(!expand)
          }}
        >
          <div className="">
            <i className={`fab fa-${PLATFORM_ICONS[char.platform]} mh2`} />
          </div>
          <div className="cbrand mr1">{char.brand}</div>
          <div className="w-100">
            {isMine ? (
              <>
                <input
                  type="text"
                  value={name}
                  onChange={(ev) => {
                    const value = ev.target.value
                    setName(ev.target.value)
                    if (value !== char.name) {
                      setDirty(1)
                    } else {
                      setDirty(0)
                    }
                  }}
                  onBlur={(e) => {
                    if (dirty === 1) {
                      upsertChar({
                        variables: {
                          id: char.id,
                          name: name,
                          meta: JSON.stringify(char.meta)
                        },
                        update: (cache, result) => {
                          setDirty(2)
                        }
                      })
                    }
                  }}
                />
                {dirty === 1 ? (
                  <i className="fas fa-save red ml2 pointer" />
                ) : dirty === 2 ? (
                  <i className="fas fa-check-square green ml2" />
                ) : null}
              </>
            ) : (
              char.name
            )}
          </div>
        </div>
        {isMine ? (
          <div className={`ml-auto ${style.clicky}`}>
            {hover ? (
              <div
                className="pointer"
                onDoubleClick={(e) => {
                  e.preventDefault()
                  deleteChar({
                    variables: { id: char.id },
                    update: (cache, result) => {
                      if (result.data.deleteCharacter.success === true) {
                        refetch()
                      }
                    }
                  })
                }}
              >
                <i className="fas fa-trash" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <CharacterShow char={char} upsertChar={upsertChar} isMine={isMine} />
    </div>
  )
}

function CharacterShow({ char, upsertChar, isMine }) {
  const [addBuild, setAddBuild] = useState(false)
  const builds = char.meta.builds || {}
  return (
    <div className="pl4 mb4">
      {Object.keys(builds)
        .sort()
        .map((key) => {
          const build = build2obj(builds[key])
          return (
            <div key={key} className="mb2 flex items-center">
              <CopyClipboard
                bclassName="button-clear-light pa2"
                value={build.raw}
              />
              <NukesNDragons search={build.raw}>
                <i className="fas fa-dragon mr2" />
                {build.name}
              </NukesNDragons>
              <div className="flex">
                {['S', 'P', 'E', 'C', 'I', 'A', 'L'].map((item, index) => {
                  return (
                    <div key={index} className="ml3">
                      <span className="f7">{item}</span>{' '}
                      <span className="fo-alt-yellow b">
                        {build.special[index]}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      {isMine ? (
        addBuild ? (
          <CharacterInput
            viewState={[addBuild, setAddBuild]}
            upsertChar={upsertChar}
            char={char}
          />
        ) : (
          <button className="button pa2" onClick={() => setAddBuild(true)}>
            <i className="fas fa-plus" /> Add Character Build
          </button>
        )
      ) : null}
    </div>
  )
}

function NukesNDragons({ children, ...more }) {
  const [pop, setPop] = useState(false)

  let { search } = more
  if (search && search.length > 0 && search[0] !== '?') {
    search = '?' + search
  }
  return (
    <>
      <button
        className="mh1 button-clear-light"
        onClick={() => {
          setPop(true)
        }}
      >
        {children}
      </button>
      {pop ? (
        <Modal viewState={[pop, setPop]} width="fw-95">
          <iframe
            title="nukes and dragons"
            src={`https://nukesdragons.com/fallout76/perks${
              search ? search : ''
            }`}
            height="100%"
            width="100%"
            style={{ border: 'none' }}
          />
        </Modal>
      ) : null}
    </>
  )
}

function CharacterInput({ viewState, char, upsertChar }) {
  const [link, setLink] = useState('')
  const [errmsg, setErrmsg] = useState('')

  return (
    <div className="mb3">
      <div className="f6 mb2">
        Insert a <NukesNDragons>Nukes &amp; Dragons</NukesNDragons>
        character build link (copy using <i className="fas fa-link" /> icon from
        Nukes &amp; Dragons webpage, then paste here). If you want more than one
        build, put a name on it at Nukes &amp; Dragons.
      </div>
      <div className="flex items-center">
        <input
          className="pa1 w-100"
          placeholder="Insert Link URL from Nukes & Dragons"
          onChange={(ev) => setLink(ev.target.value)}
        />
        <button
          className="button pa1"
          onClick={() => {
            const { error, build } = parseBuild(link)
            if (error) {
              setErrmsg(error)
            } else {
              const meta = char.meta
              const builds = meta.builds || {}
              meta['builds'] = builds
              builds[build.name] = build.raw
              upsertChar({
                variables: {
                  id: char.id,
                  name: char.name,
                  meta: JSON.stringify(meta)
                },
                update: (cache, result) => {
                  viewState[1](false)
                }
              })
            }
          }}
        >
          Save
        </button>
      </div>
      {errmsg ? <div className="red mt2">{errmsg}</div> : null}
    </div>
  )
}

function parseBuild(input) {
  let url
  try {
    url = new URL(input)
  } catch (err) {
    return { build: null, error: 'Unable to parse URL' }
  }
  if (url.origin !== 'https://nukesdragons.com') {
    return { build: null, error: 'Not a Nukes & Dragons URL' }
  }
  let build
  try {
    build = build2obj(url.search)
  } catch (err) {
    return { build: null, error: 'Unable to parse URL' }
  }
  return {
    build: build
  }
}

function build2obj(input) {
  const params = new URLSearchParams(input)
  return {
    raw: input,
    ver: params.get('v'),
    name: params.get('n') || 'no-name',
    special: params
      .get('s')
      .split('')
      .map((i) => parseInt(`0x${i}`))
  }
}

export default CharacterDetail
