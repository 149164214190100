import gql from 'graphql-tag'

export const LOAD_SYSTEMS = gql`
  query gameSystems($name: String) {
    gameSystems(name: $name) {
      id
      name
      platforms {
        id
        name
      }
    }
  }
`
