// export function decoratedUser(profile) {
//   let roles = new Set()
//   let actions = new Set()
//   if (profile.access) {
//     if (profile.access.roles) roles = new Set(profile.access.roles)
//     if (profile.access.actions) actions = new Set(profile.access.actions)
//   }
//
//   let displayEmail
//   let verified = false
//   if (profile.emails && profile.emails.length > 0) {
//     const vemails = profile.emails.filter((e) => e.verified)
//     if (vemails.length > 0) {
//       verified = true
//       displayEmail = vemails[0].address
//     } else {
//       displayEmail = profile.emails[0].address
//     }
//   }
//
//   const authStatus = (profile ? profile.authStatus : undefined) || 'unknown'
//   return Object.assign({}, profile, {
//     roles,
//     actions,
//     displayEmail,
//     authStatus,
//     verified,
//     can: (action) => actions.has(action),
//     isIdentified: authStatus !== 'unknown',
//     isAuthed: ['authed', 'multi-authed'].includes(authStatus)
//   })
// }
//
// const defaultUserAttrs = {
//   settings: {},
//   handle: '',
//   name: '',
//   emails: [],
//   subscriptions: [],
//   unreadThreads: 0,
//   authStatus: 'unknown',
//   access_token: undefined,
//   access_token_expires: 0,
//   validation_token: undefined
// }
//
// export const defaultUser = decoratedUser(defaultUserAttrs)

import config from 'constants/config'

export function defaultUser() {
  return {
    id: '',
    settings: {},
    handle: '',
    name: '',
    age: 0,
    verified: false,
    avatar: { url: '' },
    displayEmail: '',
    dataTypes: {
      available: { value: {} },
      tags: { value: {} },
      profile: { value: {} },
      address: { value: {} },
      toggles: { value: {} },
      roles: { value: {} },
      skills: { value: {} }
    },
    emails: [],
    data: [],
    ...normalizeUserParam('data', []),
    ...normalizeUserParam('tags', []),
    subscriptions: [],
    unreadThreads: 0,
    authStatus: 'unknown',
    access_token: undefined,
    access_token_expires: 0,
    validation_token: undefined,
    can: (x) => false,
    is: (x) => false,
    allows: (x, y) => false,
    isIdentified: false,
    isAuthed: false
  }
}

// input 'key' and value, always return new dictionary values that
// are to be merged into parent dictionary
export function normalizeUserParam(key, value) {
  switch (key) {
    case 'data':
      // TODO:
      // * switch address to 'location' or 'locale'
      // * switch profile to ... something else
      const data = {
        dataTypes: {
          available: { value: {} },
          tags: { value: {} },
          profile: { value: {} },
          address: { value: {} },
          toggles: { value: {} },
          roles: { value: {} },
          skills: { value: {} },
          ...(value || []).reduce((a, d) => {
            a[d.type] = d
            return a
          }, {})
        }
      }
      data.toggles = data.dataTypes.toggles.value
      return data

    case 'tags':
      return {
        tags: value,
        tagsD: (value || []).reduce((acc, tag) => {
          const current = acc[tag.type] || []
          // collapse usermap and tag
          acc[tag.type] = current.concat({
            ...tag,
            ...tag.tag,
            user_tag_id: tag.id
          })
          return acc
        }, {})
      }

    case 'emails':
      if (value && value.length > 0) {
        const vemails = value.filter((e) => e.verified)
        let verified = false
        let displayEmail = ''
        if (vemails.length > 0) {
          verified = true
          displayEmail = vemails[0].address
        } else {
          displayEmail = value[0].address
        }
        return { verified, displayEmail }
      }
      return {}

    default:
      return { [key]: value }
  }
}

function normalizeUserAvatar(value) {
  if (value.path) {
    return { ...value, url: config.url.pub + value.path }
  }
  return { ...value }
}

// this is intentionally non-immutable, which is only done for performance
// on high level things like a list of a lot of users.  Don't put a lot into
// this -- most things should go into normalizeUser and create a new instance
export function normalizePublicUser(user, real) {
  if (user._normal) return user

  user.allows = (actor, action) => {
    return actor.id === user.id || actor.is('superadmin')
  }

  user.age = (Date.now() - new Date(user.insertedAt).getTime()) / 86400000

  user.avatar = normalizeUserAvatar(user.avatar)

  // not a fan of this (Object.assign()) pattern -BJG
  Object.assign(user, normalizeUserParam('tags', user.tags))

  Object.assign(user, normalizeUserParam('data', user.data))

  user._last = Date.now()
  user._normal = real || false

  return user
}

// this always returns a new object
export function normalizeUser(indata, real) {
  let roles = new Set()
  let actions = new Set()
  if (indata.access) {
    if (indata.access.roles) roles = new Set(indata.access.roles)
    if (indata.access.actions) actions = new Set(indata.access.actions)
  }

  const authStatus = (indata ? indata.authStatus : undefined) || 'unknown'

  const user = {
    ...defaultUser(),
    ...indata,
    roles,
    actions,
    authStatus,
    ...normalizeUserParam('emails', indata.emails),
    can: (action) => actions.has(action),
    is: (role) => roles.has(role),
    isIdentified: authStatus !== 'unknown',
    isAuthed: ['authed', 'multi-authed'].includes(authStatus),
    _normal: false
  }

  let u = normalizePublicUser(user, real)
  return u
}
