import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Select from 'my-react-select'

import {
  CHARACTERS,
  UPSERT_CHARACTER,
  DELETE_CHARACTER
} from '../../../../constants/Character'
import NotSignedIn from 'tools/IdentifiedUser/NotSignedIn'
import { selectStyles } from '../../../../constants/selectStyles'
import { iconMap, platforms } from 'constants/icons'

import log from '../../../../utils/log'
import GlobalContext from 'reducer/global'
import { strcmp } from '../../../../utils/string'

import { enrichCharBrands } from '../Brand'
import CharacterDetail from './Detail'

////////////////////////////////////////////////////////////////////////////////
function CharacterTabList({ isMine, player }) {
  const [store] = useContext(GlobalContext)
  const [add, setAdd] = useState(false)
  const { loading, error, data, refetch } = useQuery(CHARACTERS, {
    variables: { systemId: store.gameSystem.id, ownerId: player.id },
    fetchPolicy: 'cache-and-network',
    skip: !store.gameSystem.id
  })
  const [deleteChar] = useMutation(DELETE_CHARACTER)
  const [addChar] = useMutation(UPSERT_CHARACTER)
  const [charName, setCharName] = useState('')
  const [charPlatform, setCharPlatform] = useState('')
  const [viewChar, setViewChar] = useState(0)

  if (!store.user.isIdentified) {
    return <NotSignedIn base={false} />
  }

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const createCharacter = () => {
    if (charName.length > 0) {
      console.log('Create char')
      log.event({ category: 'chg', action: 'Character:createCharacter' })
      addChar({
        variables: {
          systemId: store.gameSystem.id,
          name: charName,
          platform: charPlatform
        },
        update: (cache, { data: { upsertCharacter } }) => {
          refetch()
          setAdd(false)
        }
      })
    } else {
      setAdd(false)
    }
  }

  // / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / / /
  const ready = !loading && !error && data
  // eslint-disable-next-line
  let val
  let characters = []
  if (ready) {
    characters = enrichCharBrands(data.characters)
  }
  const options = platforms.map((key) => iconMap(key))

  if (!ready) return null
  return (
    <>
      <div className="tabs w-100 flex items-center justify-between">
        {characters
          .sort((a, b) => strcmp(a.name, b.name))
          .map((char, x) => (
            <div
              key={x}
              className={`tab w-100 pa1 pa2-m pa2-l f7 f6-l f6-m ${
                viewChar === x ? 'active' : ''
              }`}
              onClick={() => {
                setAdd(false)
                setViewChar(x)
              }}
            >
              {char.name}
            </div>
          ))}
        {isMine ? (
          <div
            className={`tab w-100 pa1 pa2-m pa2-l f7 f6-l f6-m `}
            onClick={() => setAdd(true)}
          >
            <i className="fas fa-plus b mr1" /> Add
          </div>
        ) : null}
      </div>
      {add ? (
        <div className="flex items-center">
          <input
            ref={(node) => (val = node)}
            onChange={(e) => {
              setCharName(e.target.value)
            }}
            autoFocus
            placeholder="Character Name"
          />
          <Select
            className="w-25"
            styles={selectStyles}
            options={options}
            onChange={(opt) => {
              setCharPlatform(opt.value)
            }}
            placeholder="Platform"
          />
          <div className="mh2 pointer hover-lighten" onClick={createCharacter}>
            <i className="fas fa-play b ml1" />
          </div>
        </div>
      ) : characters.length > 0 ? (
        <CharacterDetail
          player={player}
          isMine={isMine}
          deleteChar={deleteChar}
          char={characters[viewChar]}
          key={characters[viewChar].id}
          refetch={refetch}
        />
      ) : null}
    </>
  )
}

export default CharacterTabList
