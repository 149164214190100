import gql from 'graphql-tag'

export const DASHBOARD = gql`
  query dashboard {
    dashboard {
      id
      results
    }
  }
`

export const DASHBOARD_SUMMARY = gql`
  query dashboardSummary($type: String!) {
    dashboardSummary(type: $type) {
      id
      results
    }
  }
`

export const UPSERT_DASHBOARD_SUMMARY = gql`
  mutation upsertDashboardSummary(
    $type: String!
    $value: String!
    $method: String!
  ) {
    upsertDashboardSummary(type: $type, value: $value, method: $method) {
      id
      results
    }
  }
`
