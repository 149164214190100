import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import style from './index.module.scss'
import axios from 'axios'

// export default function StaticPage({ path }) {
//   return (
//     <div className="pa4-ns flex justify-center h-100">
//         <div className="ph2 pt2 pb4 pb2-ns fo-frame">
//           <Inject path={path} />
//         </div>
//     </div>
//   )
// }

export function Redirect({ path }) {
  const history = useHistory()

  useEffect(() => {
    history.replace(path)
  }, [path, history])

  return <></>
}

export function Inject({ path }) {
  const [content, updateContent] = useState('')

  axios.get(`/assets/div/${path}`).then((res) => {
    updateContent(res.data)
  })
  return <div dangerouslySetInnerHTML={{ __html: content }} />
}
