import React from 'react'
import { useTitle } from 'reducer/global'
import Editable from 'tools/Editable'
import { UserContext, UserProvider, R_USER } from './reducer'
import { LOAD_USER, UPDATE_PERSON } from './graphql'
import paths from 'constants/paths'
import Contact from './Contact'
import Account from './Account'
import ResetPassword from './Account/ResetPassword'
import { Profile } from '../Social'
import { DeleteAccountConfirm } from './Account/DeleteAccount'
import { normalizeTabs } from 'tools/NavTabs'

export const frame = {
  tabs: normalizeTabs(
    {},
    {
      root: paths.user,
      tabs: [
        {
          id: 'profile',
          name: 'Profile',
          Component: Profile
        },
        {
          id: 'contact',
          name: 'Contact',
          Component: Contact
        },
        // {
        //   id: 'subs',
        //   name: 'Subscriptions',
        //   Component: EmailPrefs
        // },
        {
          id: 'account',
          name: 'Account',
          Component: Account
        },
        {
          id: 'delete',
          name: 'DELETE ACCOUNT',
          view: (x) => false,
          Component: DeleteAccountConfirm,
          hideTabs: true
        },
        {
          id: 'pwreset',
          name: 'Reset Password',
          view: (x) => false,
          Component: ResetPassword,
          hideTabs: true
        }
      ],
      path: ({ tabId, params, targetId }, state) => {
        return `${paths.user}/${targetId || params?.targetId || '_'}/${tabId}`
      }
    }
  ),
  Context: UserContext,
  Provider: UserProvider,
  DO_LOAD: R_USER.SET,
  DO_MERGE: R_USER.MERGE,
  LOAD: LOAD_USER,
  SAVE: UPDATE_PERSON,
  queryName: 'user',
  componentName: false,
  oneLoadResult: true,
  pathKey: 'handle',
  // controlView: (id) => `${paths.userPublic}/${id}`,
  basePath: paths.user,
  // debug: true,
  noTarget(params, state, glob) {
    const { code, tabId } = params
    const targetId = glob.user.handle

    if (targetId) {
      return `${paths.user}/${targetId}/${tabId}${code ? '/' + code : ''}`
    } else {
      return undefined
    }
  }
}

function User() {
  useTitle('User')

  return (
    <div className="mb5">
      <Editable frame={frame} />
    </div>
  )
}

export default User
