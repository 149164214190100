import React from 'react'

export const ICON = {
  edit: 'fas fa-pen',
  chat: 'fas fa-comment-alt',
  hide: 'fas fa-ban',
  close: 'fas fa-times',
  view: 'fas fa-eye'
}

export const GLYPH = {
  dot: <>&#183;</>
}

export const iconsList = [
  { label: 'PS4', value: 'ps4', icon: 'fab fa-playstation', platform: 'ps4' },
  { label: 'Xbox', value: 'xbox', icon: 'fab fa-xbox', platform: 'xbox' },
  { label: 'PC', value: 'pc', icon: 'fab fa-windows', platform: 'pc' },
  { label: 'Bethesda.net', value: 'bethesda.net', icon: 'fas fa-cog' },
  { label: 'Reddit', value: 'reddit', icon: 'fab fa-reddit-alien' },
  { label: 'Discord', value: 'discord', icon: 'fab fa-discord' },
  { label: '_', value: 'other', icon: 'fas fa-bullseye' }
]
export const iconsDict = iconsList.reduce((acc, item) => {
  acc[item.value] = item
  acc[item.label] = item
  return acc
}, {})

export const platforms = iconsList
  .filter((icon) => icon.platform)
  .map((icon) => icon.platform)

export function iconMap(key) {
  let icon = iconsDict[key]
  if (!icon) {
    icon = iconsDict['other']
  }
  return icon
}

export function iconMapHandle(key, handle) {
  const icon = iconMap(key)

  switch (key) {
    case 'bethesda.net':
      return [
        icon,
        <>
          {handle} <span className="f6">(bethesda.net)</span>
        </>
      ]
    default:
      return [icon, handle]
  }
}
