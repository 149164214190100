import { useContext, useEffect, useCallback, useMemo } from 'react'
import GlobalContext from './index'
import { R_GLOBAL } from './reducer'
import { useHistory } from 'react-router-dom'
import { setRemove, setAdd } from 'utils/array'
import { useSearchParams } from 'tools/SearchParams'

export function useTitle(title) {
  const [{ page }, dispatch] = useContext(GlobalContext)
  if (page.title !== title) {
    dispatch({ type: R_GLOBAL.PAGE_TITLE, value: title })
  }
}

// usePage({name}) - only used for top-levels, not every discrete page
// usePage({background}) - image|flat - how to display the page
// usePage({image}) - image used on background, don't change this
export function usePage(args) {
  const [{ page }, dispatch] = useContext(GlobalContext)

  const needsChange = Object.keys(args).reduce(
    (need, key) => need || page[key] !== args[key],
    false
  )
  useEffect(() => {
    if (needsChange) {
      // // special side-effects
      // if (args.section) {
      //   // // history used in navigator
      //   // args.history = page.history
      //   //   .filter((n) => n !== args.section)
      //   //   .concat(args.section)
      // }
      dispatch({ type: R_GLOBAL.PAGE_SET, value: { saving: false, ...args } })
    }

    // disabled because I don't need it triggering when args is different -BJG
    // eslint-disable-next-line
    // x
  }, [needsChange, dispatch, args])
}

////////////////////////////////////////////////////////////////////////////////
export function useDelay(key) {
  const [{ prefs }, dispatch] = useContext(GlobalContext)
  useEffect(() => {
    if (prefs.skip === false) {
      return
    }
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        dispatch({ type: R_GLOBAL.PREFS_READY })
      }
    }, 400)
    // return the function to "unmount"
    return () => {
      isMounted = false
    }
  }, [prefs.skip, dispatch])
}

export const SEPARATOR = '~' // also ok:  *  .
export function useExpandQuery(tok) {
  const query = useSearchParams()
  const history = useHistory()

  const expand = useMemo(() => {
    let qexpand = query.get(tok)
    let expand = []
    if (qexpand !== null) expand = qexpand.split(SEPARATOR)
    return expand
  }, [tok, query])

  const pivot = useCallback(
    (id) => {
      if (expand.includes(id)) {
        query.set(tok, setRemove(expand, id).join(SEPARATOR))
      } else {
        query.set(tok, setAdd(expand, id).join(SEPARATOR))
      }
      history.push(window.location.pathname + '?' + query.toString())
    },
    [history, query, expand, tok]
  )

  return { expand, pivot, query }
}
