import React from 'react'

export function NotSignedIn({ base = undefined, frame = true }) {
  const Inner = () => (
    <div className={`f4 tc pv5 ph2 ${frame ? 'fo-frame' : ''} w-100`}>
      <div className="tc fo-alt-yellow f3">Welcome Vault Dweller!</div>
      <div className="mv3">
        We are sorry to inform you that for this feature to work properly the
        bureaucrats would like you to sign on first.
      </div>
    </div>
  )
  if (base) {
    return (
      <div className="mh1-ns mh3-m mh4-l mt3-ns mt4-l">
        <Inner />
      </div>
    )
  } else {
    return <Inner />
  }
}
export default NotSignedIn
