import React, { useContext, useCallback } from 'react'
import { CHANGE_PASSWORD } from '../graphql'
import { useMutation } from '@apollo/client'
import { UserContext, R_USER } from '../reducer'
import { useReady, passwordStyle, passwordChange, savePassword } from './lib'

function NewPassword({ isAuthed }) {
  const [user, dispatch] = useContext(UserContext)
  const form = user.passform
  const [mutation] = useMutation(CHANGE_PASSWORD)
  const updateForm = useCallback(
    (merge) => {
      dispatch({ type: R_USER.CHANGE_PASSFORM, merge })
    },
    [dispatch]
  )
  const isReady = useReady(isAuthed)

  return (
    <div
      className="mt3"
      onBlur={(ev) => {
        ev.stopPropagation()
        updateForm({ showPass: false })
      }}
    >
      <div className="b f6">
        New Password
        <i
          className="fas fa-eye ml3 hover pointer"
          onClick={() => updateForm({ showPass: !form.showPass })}
        />
      </div>
      <div className="mt2">
        <input
          type={form.showPass ? 'text' : 'password'}
          style={passwordStyle}
          value={form.password1}
          placeholder="New Password"
          onChange={(ev) =>
            passwordChange(dispatch, ev, 'password1', form, isReady)
          }
        />
      </div>
      <div className="mt2">
        <input
          type={form.showPass ? 'text' : 'password'}
          style={passwordStyle}
          value={form.password2}
          placeholder="New Password (again)"
          onChange={(ev) =>
            passwordChange(dispatch, ev, 'password2', form, isReady)
          }
        />
      </div>
      <div className="mv3">{form.passMsg}</div>
      <button
        className="medium plain mt2"
        onClick={() => savePassword(dispatch, mutation, user, isAuthed)}
        disabled={!form.good}
      >
        Change
      </button>
    </div>
  )
}

export default NewPassword
